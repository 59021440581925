import React, { useState, useCallback } from 'react';
import { Button, Box, Grid, Paper, IconButton, Typography, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import styled from 'styled-components';


const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

function Fileupload3({ labelinput, setShow1, files, setFiles, setDeleteImage, deleteImage }) {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event) => {
    //size 5 mb up then not upload
    if (event.target.files && event.target.files[0] && event.target.files[0].size > 5000000) {
      setShow1({
        show: true,
        type: 'error',
        message: "File is too big!" || 'Something went wrong',
      });

      return;
    }

    setFiles([...files, { image: event.target.files[0] }]);
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback((event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size > 5000000) {
      setShow1({
        show: true,
        type: 'error',
        message: "File is too big!" || 'Something went wrong',
      });
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length) {
      setFiles([...files, { image: droppedFiles[0] }]);
    }
  }, [files]);

  const handleRemoveFile = (index, file) => {
    console.log("filefilefilefile", file);
    if (file?.id) {
      setDeleteImage([...deleteImage, file.id])
    }
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleUploadClick = () => {
    // Upload logic goes here
  };

  return (
    <Box>
      {labelinput && <Typography fontSize={'16px'} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
      <label htmlFor="raised-button-file2" onClick={handleUploadClick}>
        <Box
          sx={{
            width: '100%',
            height: '70px',
            border: '1px dashed #c4c4c4',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: dragging ? '#e0e0e0' : '#f8f8f8',
            fontSize: '14px',
            fontWeight: '600',
            cursor: 'pointer'
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          Drop your files here or click to browse
          <input
            accept="application/pdf"
            style={{ display: 'none' }}
            id="raised-button-file2"
            multiple
            type="file"
            onChange={handleFileChange}
          />
        </Box>
      </label>

      {/* Thumbnails */}
      <Stack gap={'10px'} flexDirection={'row'} flexWrap={'wrap'} style={{ marginTop: '10px' }}>
        {files?.map((file, index) => (
          file?.image != undefined &&
          <Box key={index} sx={{ width: '100px', height: '80px', position: 'relative', border: '1px solid #e3e3e3', borderRadius: '10px' }} onClick={() => { window.open(file.image, '_blank') }} style={{
            cursor: "pointer",
          }} >
            <IconButton
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveFile(index, file)
              }}
            >
              <DeleteIcon sx={{ fontSize: '16px' }} />
            </IconButton>

            {file && file?.id ? (
              <>
                <StyledIconWrapper
                  style={{
                    margin: "0",
                    display: "flex",
                    justifyContent: "center",
                    background: "none",
                    border: "none",
                    width: "auto",
                    height: "50px",
                  }}
                >
                  <PictureAsPdfIcon
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "rgb(118 116 107)",
                    }}
                  />
                </StyledIconWrapper>
                <p style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "rgb(118 116 107)",
                }} >
                  {file?.image?.name?.length > 10
                    ? file?.image?.name?.slice(0, 10) + "..."
                    : file?.image.substring(file?.image?.lastIndexOf('/') + 1).slice(0, 13) + "..."}
                </p>
              </>
            ) : (
              file?.image != undefined && (<><StyledIconWrapper
                style={{
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                  background: "none",
                  border: "none",
                  width: "auto",
                  height: "50px",
                }}
              >
                <PictureAsPdfIcon
                  style={{
                    width: "40px",
                    height: "40px",
                    color: "rgb(118 116 107)",
                  }}
                />
              </StyledIconWrapper>
                <p>
                  {file.image.name.length > 10
                    ? file.image.name.slice(0, 10) + "..."
                    : file.image.slice(0, 10) + "..."}
                </p>
              </>)
            )}
          </Box>
        ))
        }
      </Stack >
    </Box >
  );
}

export default Fileupload3;