import React, { useState } from "react";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  IconButton,
  Tooltip,
  Tab,
} from "@mui/material";
import Heading from "../../../components/Heading";
import CommonButton from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import { FaEdit, FaEye } from "react-icons/fa";
import TableSearch from "../../../components/common/Table/TableSearch";
import { MdDelete } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
const data = [
  {
    id: 1,
    productname: "Poleto green",
    stockonhand: 100,
    commitedstock: 10,
    availableforsale: 90,
    date: "10/10/2024",
  },
  {
    id: 2,
    productname: "Black Fox ",
    stockonhand: 96,
    commitedstock: 50,
    availableforsale: 80,
    date: "11/10/2024",
  },
  {
    id: 3,
    productname: "Carrara",
    stockonhand: 100,
    commitedstock: 10,
    availableforsale: 90,
    date: "12/10/2024",
  },
];
const Stock = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const navigation = useNavigate()
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Inventory Management"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TableSearch />
          {/* <CommonButton
            buttonName="Add Surface"
            onClick={() => {
              setIsModalOpen({
                open: true,
                currentComponent: "AddSurface",
              });
            }}
          /> */}
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Stock On Hand</TableCell>
                  <TableCell>Commited Stock</TableCell>
                  <TableCell>Available For Sale</TableCell>
                  <TableCell>Date</TableCell>

                  <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id} sx={{cursor:"pointer"}} >
                    <TableCell onClick={()=>navigation('/view-product')}>{row.productname}</TableCell>
                    <TableCell onClick={()=>navigation('/view-product')}>{row.stockonhand}</TableCell>
                    <TableCell onClick={()=>navigation('/view-product')}>{row.commitedstock}</TableCell>
                    <TableCell onClick={()=>navigation('/view-product')}>{row.availableforsale}</TableCell>
                    <TableCell onClick={()=>navigation('/view-product')}  >{row.date}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="View Details">
                          <IconButton size="small" onClick={()=>navigation('/view-product')}>
                            <FaEye />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Details">
                          <IconButton
                            // onClick={() => {
                            //   setIsModalOpen({
                            //     open: true,
                            //     currentComponent: "AddSurface",
                            //   });
                            // }}
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                          >
                            <MdDelete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default Stock;
