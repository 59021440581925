import {
  Box,
  Chip,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import { FaEdit, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/common/Button";
import CommonModal from "../../components/common/Modal";
import { MdDownload } from "react-icons/md";
import TableSearch from "../../components/common/Table/TableSearch";
import { DealerDelete, DealerList, DealerStatusChange } from "../../Service/dealer.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import Pagination from "../../components/common/Table/Pagination";

const Party_member = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const dispatch = useDispatch();
  const [dealerData, setDealerData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getDealerList = async () => {
    setDealerData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await DealerList(query);
    if (res?.status) {
      setDealerData(res?.data);
      setTotalData(res?.total);
    } else {
      setDealerData(null);
    }
  }

  useEffect(() => {
    getDealerList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);


  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == 'status' ? await DealerStatusChange(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await DealerDelete(isModalOpen?.id) : null;
    console.log("res", res);

    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getDealerList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || 'Something went wrong. Please try again later.'
      }))
    }
  }

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Dealer Details"} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          </Box>
          <Box>
            <CommonButton
              buttonName="Add Dealer"
              onClick={() => navigate("/dealer-form", { state: { isEdit: false } })}
            />
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>SR</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Mobile No.</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Version</TableCell>
              <TableCell sx={{ textAlign: 'end' }}>Action</TableCell>
            </TableHead>
            <TableBody>
              {dealerData && dealerData.map((row, index) => (
                <TableRow key={index} sx={{ cursor: 'pointer' }} >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell onClick={() => navigate("/view-dealer", { state: { id: row?.id, index:row?.index } })}>
                    {row.authPersonName}
                  </TableCell>
                  <TableCell>{row.categoryName}</TableCell>
                  <TableCell onClick={() => navigate("/view-dealer", { state: { id: row?.id, index: row?.index } })}>
                    {row.mobile}
                  </TableCell>
                  <TableCell onClick={() => navigate("/view-dealer", { state: { id: row?.id, index:row?.index } })}>
                    {row.email}
                  </TableCell>
                  <TableCell onClick={() => navigate("/view-dealer", { state: { id: row?.id, index:row?.index } })}>
                    {row.city}
                  </TableCell>
                  <TableCell onClick={() => navigate("/view-dealer", { state: { id: row?.id, index:row?.index } })}>
                    {row.state}
                  </TableCell>
                  <TableCell onClick={() => navigate("/view-dealer", { state: { id: row?.id, index:row?.index } })}>
                    {row.zone === 1 ? "East" : row.zone === 2 ? "West" : row.zone === 3 ? "North" : row.zone === 4 ? "South" : ""}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={row.version}
                      sx={{ backgroundColor: "primary" }}
                      color="primary"
                    />
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip title="View Details">
                        <IconButton
                          onClick={() => navigate("/view-dealer", { state: { id: row?.id, index:row?.index } })}
                          size="small"
                        >
                          <FaEye />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Details">
                        <IconButton
                          onClick={() => navigate("/dealer-form", { state: { id: row?.id, isEdit: true, currentTab: 0 } })}
                          size="small"
                        >
                          <FaEdit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download PDF">
                        <IconButton size="small">
                          <MdDownload />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {dealerData !== null && (
        <Pagination totalRecords={totalData}
          data={dealerData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />)}
      <CommonModal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
          getDealerList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Party_member;
