import React from "react";
import PageHeading from "../../components/common/PageHeading";
import CounterCard from "./CounterCard";
import { Grid, IconButton, Paper, Stack } from "@mui/material";

import { useStyles } from "../../helper/Theme";
const data = [
  {
    productName: "Product 1",
    location: "Location 1",
    rackLocation: "17B34",
    movementCount: "10",
    updatedby: "User 1",
    updatedAt: "10/10/2024 10:00 AM",
  },
  {
    productName: "Product 2",
    location: "Location 2",
    rackLocation: "17B35",
    movementCount: "20",
    updatedby: "User 2",
    updatedAt: "3/10/2024 11:00 AM",
  },
  {
    productName: "Product 3",
    location: "Location 3",
    rackLocation: "17B36",
    movementCount: "30",
    updatedby: "User 3",
    updatedAt: "04/10/2024 10:00 AM",
  },
  {
    productName: "Product 4",
    location: "Location 4",
    rackLocation: "17B37",
    movementCount: "40",
    updatedby: "User 4",
    updatedAt: "05/10/2024 10:00 AM",
  },
  {
    productName: "Product 5",
    location: "Location 5",
    rackLocation: "17B38",
    movementCount: "50",
    updatedby: "User 1",
    updatedAt: "10/10/2024 10:00 AM",
  },
];

const DashBoard = () => {
  const classes = useStyles();

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: 1 },
          flexDirection: { xs: "column", sm: "row" },
          gap: "8px",
        }}
      >
        <PageHeading heading="Dashboard" />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          gap={{ xs: "5px", sm: "10px" }}
          width="inherit"
        >
          <Grid item>{/* <DateRange /> */}</Grid>
        </Grid>
      </Stack>
      <Grid container spacing={{ xs: "10px", sm: 3 }}>
        <Grid item xs={12}>
          <CounterCard />
        </Grid>
        {/* <Grid item xs={12}>
          <Heading head={"Top 10 Moving Product"} />
          <TableContainer component={Paper} sx={{ marginTop: "10px" }} className={classes.tableContainer}>
            <Table stickyHeader aria-label="simple table" >
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell> Rack Location</TableCell>
                  <TableCell>Movement Count</TableCell>
                  <TableCell>Updated by</TableCell>
                  <TableCell>Updated at</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.productName}>
                    <TableCell>{row.productName}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.rackLocation}</TableCell>
                    <TableCell>{row.movementCount}</TableCell>
                    <TableCell>{row.updatedby}</TableCell>
                    <TableCell>{row.updatedAt}</TableCell>
                    <TableCell>
                      <Tooltip title="View Details">
                        <IconButton>
                          <FaEye />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> */}
      </Grid>
    </>
  );
};

export default DashBoard;
