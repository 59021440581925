import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CommonButton from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import { CloseIcon } from "../../../helper/Icons";
import { AddSurface, getSurfaceById, updateSurface } from "../../../Service/surface.service";
import { addSurfaceInitialValues } from "../../../helper/initialValues";
import { surfaceValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useEffect } from "react";

const AddSurface_modal = ({modalOpen, handleClose, handelClick }) => {
    const id = modalOpen.id;

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

    const formik = useFormik({
    initialValues: addSurfaceInitialValues,
    onSubmit: async (value) => {
      setLoading(true);
      const payload = {
        surfaceName: value.surfaceName,
        surfaceDetail: value.surfaceDetail,
      };

      const res = id ? await updateSurface(id, payload) : await AddSurface(payload);
      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Size Added Successfully.'
        }
        ))
        handleClose();
      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: surfaceValidationSchema,
    });
  
  const getSizeDetail = async () => {
    const res = await getSurfaceById(id);
    if (res.status) {
      formik.setFieldValue("surfaceName", res.data.surfaceName);
      formik.setFieldValue("surfaceDetail", res.data.surfaceDetail);
    }
  }

  useEffect(() => {
    if (id) {
      getSizeDetail();
    }
  }, [])
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        <Typography variant="h6">{id ? "Edit Surface" : "Add Surface"}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth: "500px", width: "100%" }}>
        <Grid container spacing={2} minWidth={{ xs:"100%",sm: "400px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Surface Name"} type="text"
              maxLength={50}
              {...formik.getFieldProps('surfaceName')}
              error={formik.touched.surfaceName && formik.errors.surfaceName}
              helperText={formik.touched.surfaceName && formik.errors.surfaceName}

            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Surface Detail"} type="text"
              maxLength={50}
              {...formik.getFieldProps('surfaceDetail')}
              error={formik.touched.surfaceDetail && formik.errors.surfaceDetail}
              helperText={formik.touched.surfaceDetail && formik.errors.surfaceDetail}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton
          buttonName="Save"
          size="small"
          color="primary"
          onClick={
            formik.handleSubmit
          } 
        />
      </DialogActions>
    </>
  );
};

export default AddSurface_modal;
