import { deleteRequest, get, post, put } from "../web.request";


export const SampleRequestDetail = async (id) => {
  return await get(`/sampleRequestDetail/${id}`);
}

export const SampleRequestList = async (query) => {
  return await get(`/sampleRequestList${query}`)
}

export const SampleRequestCreate = async (data) => {
  return await post(`/createSampleRequest `, data);
}

export const SampleRequestUpdate = async (id, data) => {
  return await put(`/updateSampleRequest/${id}`, data);
}

export const SampleRequestDelete = async (id) => {
  return await deleteRequest(`/deleteSampleRequest/${id}`);
}

export const SampleRequestStatusChange = async (id, data) => {
  return await put(`/statusChangeSampleRequest/${id}`, data);
}
