import { get, post,put,deleteRequest, } from "../web.request";

export const AddTaxConfig = async (data) => {
  return await post(`/createTaxConfig`, data);
}

export const updateTaxConfig = async (id,data) => {
  return await put(`/updateTaxConfig/${id}`, data);
}

export const taxConfigList = async (query) => {
  return await get(`/taxConfigList${query}`);
}

export const taxConfigDropdown = async () => {
  return await get(`/taxConfigDropdown`);
}

export const deleteTaxConfig = async (id) => {
  return await deleteRequest(`/deleteTaxConfig/${id}`);
}

export const getTaxConfigById = async (id) => {
  return await get(`/taxConfigDetail/${id}`);
}

export const statusChangeTaxConfig = async (id) => {
  return await put(`/statusChangeTaxConfig/${id}`);
}


