import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../../components/common/Button';
import CommonInput from '../../components/common/Input';
import Notification from './Notification/Notification';
import { changePasswordValidationSchema } from '../../helper/validation';
import { useFormik } from 'formik';
import { changePasswordInitialValues } from '../../helper/initialValues';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { changePassword } from '../../Service/user.service';

const ChangePassword = ({ handleClose, show, setShow, modalOpen }) => {
    const [show1, setShow1] = useState(false);
    const { user } = useSelector((state) => state?.root?.auth);
    const id = user?.id;
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: changePasswordInitialValues,
        validationSchema: changePasswordValidationSchema,
        onSubmit: async (value) => {
            setLoading(true);
            const payload = {
                oldPassword: value.oldPassword,
                newPassword: value.newPassword,
            };

            const res = await changePassword(id, payload);
            if (res.status) {
                setLoading(false);
                dispatch(notificationSuccess({
                    show: true,
                    type: 'success',
                    message: res.message || 'Password changed successfully.'
                }));
                handleClose();
            } else {
                setLoading(false);
                dispatch(notificationSuccess({
                    show: true,
                    type: 'error',
                    message: res.message || 'Something went wrong.'
                }));
            }
        },
    });

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Change Password
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
                        <Grid item xs={12}>
                            <CommonInput
                                labelinput='Current Password'
                                passwordInput
                                name='oldPassword'
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CommonInput
                                labelinput='New Password'
                                passwordInput
                                name='newPassword'
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CommonInput
                                labelinput='Confirm Password'
                                passwordInput
                                name='confirmpass'
                                value={formik.values.confirmpass}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.confirmpass && Boolean(formik.errors.confirmpass)}
                                helperText={formik.touched.confirmpass && formik.errors.confirmpass}
                            />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <CommonButton buttonName='Cancel' size='small' color='white' onClick={handleClose} />
                        <CommonButton buttonName='Save' size='small' type='submit' loading={loading} />
                    </DialogActions>
                </form>
            </DialogContent>
            <Notification show={show1} setShow={setShow1} />
        </>
    );
}

export default ChangePassword;
