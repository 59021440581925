import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CommonButton from "../Button";
import { LogoutIcon } from "../../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";
import { logoutApi } from "../../../state/Action/authAction";
import { useDispatch } from "react-redux";

const DeleteModal = (props) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setOpenDelete(true);
  };
  const handleCloseModal = () => {
    setOpenDelete(false);
  };
  const navigate = useNavigate();

  const handelLogout = () => {
    localStorage.removeItem('token');
    dispatch(logoutApi());
    navigate('/login');
  }
  return (
    <>
      <div onClick={handleOpenModal}>
        <Grid container spacing={1}>
          <Grid item>
            <LogoutIcon color="primary" />
          </Grid>
          <Grid item>Logout</Grid>
        </Grid>
      </div>

      <Dialog
        open={openDelete}
        onClose={handleCloseModal}
        sx={{ "& .MuiDialog-paper": { width: "500px" } }}
      >
        <DialogTitle>Logout</DialogTitle>
        <DialogContent dividers>
          <Typography
            className="myprofile_input_label"
            sx={{ paddingBottom: "2px" }}
          >
            Are You Sure you want to logout ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CommonButton onClick={handleCloseModal} buttonName="No" />
          <CommonButton onClick={() => handelLogout()} buttonName="Yes" />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteModal;
