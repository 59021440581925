import React from "react";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";

const PhotoUpload = ({ file, setFile }) => {
  const handleFileChange = (event) => {
    const newFile = event.target.files[0]; // Get the first file
    if (newFile) {
      setFile(newFile); // Set the file in state
    }
  };

  const handleDelete = () => {
    setFile(null); // Remove the file
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="upload-button"
        type="file"
        onChange={handleFileChange} // No 'multiple' attribute for single file
      />
      <label
        htmlFor="upload-button"
        style={{
          padding: 3,
          border: "1px solid black",
          borderRadius: "5px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <IconButton color="primary" aria-label="upload picture" component="span">
          <UploadIcon />
        </IconButton>
        Upload Photo
      </label>

      {file && (
        <Box position="relative">
          {/* Check if the file is of type 'object' before creating a URL */}
          <img
            src={file instanceof Blob ? URL.createObjectURL(file) : ''}
            alt={`upload-preview`}
            style={{ width: 100, height: 100, objectFit: "cover" }}
          />
          <IconButton
            size="small"
            color="secondary"
            onClick={handleDelete}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default PhotoUpload;