import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState,useEffect } from "react";
import BackButton from "../../../components/common/Backbutton";
import Heading from "../../../components/Heading";
import Input from "../../../components/common/Input";
import PhotoUpload from "./PhotoUpload";
import CommonButton from "../../../components/common/Button";
import { UserCreate, UserDetail, UserUpdate } from "../../../Service/user.service";
import { adduserInitialValues } from "../../../helper/initialValues";
import { userValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useLocation, useNavigate } from "react-router-dom";
import { RoleDropdown } from "../../../Service/Role.service";

const AddUser = () => {
  const [file, setFile] = useState(null);
  const [deleteImage, setDeleteImage] = useState([]);
  const [roledata, setRoleData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

    const formik = useFormik({
    initialValues: adduserInitialValues,
    onSubmit: async (value) => {
      setLoading(true);
      //using formData
      const formData = new FormData();
      formData.append('firstName', value.firstName);
      formData.append('lastName', value.lastName);
      formData.append('email', value.email);
      formData.append('mobile', value.mobile);
      formData.append('role', value.role);
      formData.append('profile', file);

      const res = isEdit ? await UserUpdate(editId, formData) : await UserCreate(formData);
      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Size Added Successfully.'
        }
        ))
        navigate('/user-management');
      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: userValidationSchema,
    });
  
  console.log("formik",formik);
   useEffect(() => {
    if (state && state?.isEdit && state?.id) {
      setIsEdit(true);
      setEditId(state.id);
      getUserDetail(state.id);
    } else {
      setEditId(null);
      setIsEdit(false);
    }

  }, [state]);

  const getUserDetail = async (id) => {
    const res = await UserDetail(id);
    if (res.status) {
      formik.setFieldValue("firstName", res.data.firstName);
      formik.setFieldValue("lastName", res.data.lastName);
      formik.setFieldValue("email", res.data.email);
      formik.setFieldValue("mobile", res.data.mobile);
      formik.setFieldValue("role", res.data.role.id);
      setFile(res.data.profile);
    }
  }

  const getDropdownData = async () => {
    let res = await RoleDropdown();
    if (res?.status) {
      setRoleData(res.data);
    } else {
      setRoleData([]);
    }
  }

  useEffect(() => {
    getDropdownData();
  }, []);

  // const handleDeleteImage = (index) => {
  //   setDeleteImage([...deleteImage, files[index]]);
  //   setFiles(files.filter((_, i) => i !== index));
  // }

  return (
    <>
      <Stack gap={2}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <BackButton />
          <Heading head={`Create User`} />
        </Box>
        <Box className="form_border">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography fontSize={"16px"} fontWeight={500} mb={"2px"}>
                Upload Photo
              </Typography>
              <PhotoUpload file={file} setFile={setFile} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"First Name"} type="text"
                {...formik.getFieldProps("firstName")}
                error={formik.touched.firstName && formik.errors.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName} 

              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Last Name"} type="text"
                {...formik.getFieldProps("lastName")}
                error={formik.touched.lastName && formik.errors.lastName}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Email"} type="text"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Mobile Number"} type="text"
                {...formik.getFieldProps("mobile")}
                error={formik.touched.mobile && formik.errors.mobile}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="standard" sx={{ marginTop: "-6px" }}>
                <Autocomplete
                  options={roledata}
                  getOptionLabel={(option) => option.name || ""} 
                  value={
                    roledata.find((role) => role.id === formik.values.role) || null
                  } // Match the current value with roledata
                  onChange={(event, newValue) => {
                    // Ensure newValue is not undefined or null
                    formik.setFieldValue('role', newValue ? newValue.id : '');
                  }}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      labelinput="Role"
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={formik.touched.role && formik.errors.role}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
            <CommonButton buttonName="Create"
              onClick={formik.handleSubmit}
            />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default AddUser;
