import React from "react";
import { Route, Routes } from "react-router-dom";
import DashBoard from "../../pages/dashboard/Dashboard";
import Party_member from "../../pages/Dealer-wise/Party_member";
import Party_Form from "../../pages/Dealer-wise";
import Selection_Confi from "../../pages/Sample Request/Index";
import Selection_container from "../../pages/Sample Request/Sample_main";
import Selection_invoice_pdf from "../../pages/Sample Request/Sample_req_invoice_pdf";
import Stock from "../../pages/Inventory/Stock/Stock";
import Master_Product from "../../pages/Inventory/Master Product/Master_Product";
import Inventory_Adjust from "../../pages/Inventory/Inventory Adjust/Inventory_Adjust";
import Surface from "../../pages/Settings/Surface/Surface";
import Size from "../../pages/Settings/Size/Size";
import Product from "../../pages/Settings/Product/Product";
import View_dealer from "../../pages/Dealer-wise/View_dealer";
import View_Sample_Requester from "../../pages/Sample Request/View_Sample_Requester";
import Text_Config from "../../pages/Settings/Text Config/Text_Config";
import Category from "../../pages/Settings/Category/Category";
import AddProduct from "../../pages/Settings/Product/AddProduct";
import Role from "../../pages/Settings/Role/Role";
import AddRole from "../../pages/Settings/Role/AddRole";
import User_management from "../../pages/Settings/User Management/User_management";
import AddUser from "../../pages/Settings/User Management/AddUser";
import Add_Inventory_Adjust from "../../pages/Inventory/Inventory Adjust/Add_Inventory_Adjust";
import ViewProduct from "../../pages/Settings/Product/ViewProduct";
import View_inventory_Adjust from "../../pages/Inventory/Inventory Adjust/View_inventory_Adjust";
import Sales_Order from "../../pages/Sales Order/Sales_Order";
import Create_sales from "../../pages/Sales Order/Create_sales";
import MyProfile from "../../pages/Profile/Profile";
import LetterheadPDF from "../../pages/Dealer-wise/react-pdf/Letterhead";
import { useSelector } from "react-redux";


const Main = () => {
  const { user } = useSelector((state) => state?.root?.auth);
  const mailArray = [
    "rahul.pathak@porcious.com",
    "sagar.pachani@porcious.com",
    "dhaval.patel@porcious.com",
    "abhinav.sinha@porcious.com"
  ]

  console.log("userdsdsdsd", mailArray.includes(user?.email));

  const pages = [
    { path: "/", element: <DashBoard />, type: "show" },
    { path: "/dealer", element: <Party_member />, type: "show" },
    { path: "/view-dealer", element: <View_dealer />, type: "show" },
    { path: "/dealer-form", element: <Party_Form />, type: "show" },
    { path: "/dealer-form-edit", element: <Party_Form />, type: "show" },
    { path: "/sample-request", element: <Selection_Confi />, type: "show" },
    { path: "/view-sample-requester", element: <View_Sample_Requester />, type: "show" },
    { path: "/sample-form", element: <Selection_container />, type: "show" },
    { path: "/sample-form-edit", element: <Selection_container />, type: "show" },
    { path: "/party-invoice", element: <LetterheadPDF /> },
    { path: "/selection-invoice", element: <Selection_invoice_pdf /> },
    { path: "/product", element: <Product /> },
    { path: "/surface", element: <Surface /> },
    { path: "/size", element: <Size /> },
    { path: '/stock', element: <Stock /> },
    { path: '/master-stock', element: <Master_Product /> },
    { path: '/inventory-adjustment', element: <Inventory_Adjust /> },
    { path: '/text-config', element: <Text_Config /> },
    { path: '/category', element: <Category /> },
    { path: '/add-product', element: <AddProduct /> },
    { path: '/role', element: <Role /> },
    { path: '/add-role', element: <AddRole /> },
    { path: '/update-role', element: <AddRole /> },
    { path: '/user-management', element: <User_management /> },
    { path: '/add-user', element: <AddUser /> },
    { path: '/add-inventory-adjustment', element: <Add_Inventory_Adjust /> },
    { path: '/view-inverntory-adjustment', element: <View_inventory_Adjust /> },
    { path: '/view-product', element: <ViewProduct /> },
    { path: '/sales-order', element: <Sales_Order /> },
    { path: '/create-sales-order', element: <Create_sales /> },
    { path: '/profile', element: <MyProfile /> },
  ];

  return (
    <div>
      <Routes>
        {pages.map(({ path, element, type }, index) => {
          if (mailArray.includes(user?.email) && type == "show") {
            return <Route key={index} path={path} element={element} />;
          } else if (!mailArray.includes(user?.email)) {
            return <Route key={index} path={path} element={element} />;
          } else {
            return <Route key={index} path={path} element={<h1>Not Found</h1>} />;
          }
        })}
      </Routes>

    </div>
  );
};

export default Main;
