import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { MdAdd } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import CommonButton from "../../components/common/Button";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/Heading";
import Input from "../../components/common/Input";
import { useNavigate } from "react-router-dom";

const Create_sales = () => {
  const [items, setItems] = useState([
    {
      id: 1,
      availableQuantity: 10,
      newQuantity: 10,
      adjustQuantity: 0,
      adjustmentType: 1,
    },
  ]);

  const addItem = () => {
    setItems([
      ...items,
      {
        id: items.length + 1,
        availableQuantity: 0,
        newQuantity: 0,
        adjustQuantity: 0,
        adjustmentType: 1,
      },
    ]);
  };

  const removeItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleNumericChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const navigate = useNavigate();

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="flex-start"
        gap={1}
        alignItems="center"
        mb={2}
      >
        <BackButton />
        <Heading head="Create Sales Order" />
      </Stack>
      <Stack className="border_card" p={2} gap={2} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Stack className=" border_card" gap={2} p={2}>
              <Grid item xs={12}>
                <Input
                  labelinput="Adjustment Date"
                  name="date"
                  variant="standard"
                  type="date"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                  <Autocomplete
                    options={["Customer 1", "Customer 2", "Customer 3"]}
                    renderInput={(params) => (
                      <Input {...params} labelinput="Customer" />
                    )}
                  />
                </FormControl>
              </Grid>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              mb={1}
              alignItems="flex-end"
            >
              <Heading smallHead="Product Details" />
            </Stack>
            <Box className="border_card" p={2}>
              {items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={["product1", "product2", "product3"]}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Product" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <Input
                        placeholder="Rate"
                        labelinput="Rate"
                        // value={item.availableQuantity}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <Input placeholder=" Quantity" labelinput=" Quantity" />
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <FormControl fullWidth sx={{ marginTop: "-6px" }}>
                        <Autocomplete
                          options={["None", "CGST", "IGST"]}
                          renderInput={(params) => (
                            <Input {...params} labelinput="Tax" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <Input
                        placeholder=" Tax Amount"
                        labelinput=" Tax Amount"
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                      <Input placeholder="  Amount" labelinput="  Amount" />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      item
                      sx={{ alignContent: "center" }}
                    >
                      {items.length - 1 === index ? (
                        <>
                          <CommonButton
                            style={{
                              padding: "10px 11px",
                              marginRight: "10px",
                            }}
                            buttonName={<MdAdd style={{ fontSize: "19px" }} />}
                            onClick={addItem}
                          />
                          {items.length !== 1 && (
                            <CommonButton
                              style={{ padding: "10px 11px" }}
                              buttonName={
                                <HiOutlineTrash style={{ fontSize: "19px" }} />
                              }
                              onClick={() => removeItem(item.id)}
                              disabled={item.newQuantity === 0}
                            />
                          )}
                        </>
                      ) : (
                        <CommonButton
                          style={{ padding: "10px 11px" }}
                          buttonName={
                            <HiOutlineTrash style={{ fontSize: "19px" }} />
                          }
                          onClick={() => removeItem(item.id)}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {items.length - 1 === index ? (
                    ""
                  ) : (
                    <Divider sx={{ marginY: 2 }} />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Stack>
      {/* <Grid item xs={12} sm={6}> */}
      <Grid container spacing={2} mt={2} sx={{display:"flex",justifyContent:"flex-end"}}>
        <Box className="border_card"  p={2}>
          <Grid item xs={12}>
            <div className="price_input">
              <Input labelinput={"Total"} variant="standard" maxLength={10} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="price_input">
              <Input
                labelinput={"Discount Percentage"}
                variant="standard"
                maxLength={10}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="price_input">
              <Input labelinput={"Sub total"} maxLength={10} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="price_input">
              <Input labelinput={"Discount Amount"} maxLength={10} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="price_input">
              <Input labelinput={"Payable Amount"} maxLength={10} />
            </div>
          </Grid>
        </Box>
      </Grid>
      {/* </Grid> */}
      <Stack
        flexDirection="row"
        gap={1}
        mt={3}
        justifyContent="flex-end"
        display={"flex"}
        flexWrap={"wrap"}
      >
        <CommonButton buttonName="Create" />
        <CommonButton
          buttonName="Cancel"
          onClick={() => navigate("/sales-order")}
          color="white"
        />
      </Stack>
    </>
  );
};

export default Create_sales;
