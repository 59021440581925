import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import CommonButton from "../../../components/common/Button";
import { UserDetail } from "../../../Service/user.service";
import moment from "moment/moment";

const ViewUserModel = ({ handleClose, modalOpen }) => {

  const [userDetail, setUserDetail] = useState({});
  useEffect(() => {
    getSizeDetail();
  }, []);


  const getSizeDetail = async () => {
    const res = await UserDetail(modalOpen.id);
    if (res.status) {
      setUserDetail(res.data);
    } else {
      setUserDetail({});
    }
  }
  
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        User Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{minWidth:{xs:'100%',sm:'500px'}}}>
        <Grid container spacing={2}>
          <Box xs={12} item sx={{display:'flex', flexDirection:'column' , justifyContent:'center', alignItems:'center', width:'100%'}} >
            <Avatar src={userDetail?.profile} sx={{ width: 80, height: 80 }}/>
            <Typography variant="h6">User Name</Typography>
            <Typography variant="body1"> {userDetail?.firstName} {userDetail?.lastName }</Typography>
          </Box>
          {/* <Grid xs={12} sm={6} item>
            <Typography variant="h6">Company Name</Typography>
            <Typography variant="body1">Porcious</Typography>
          </Grid> */}
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Email</Typography>
            <Typography variant="body1">{userDetail?.email}</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Mobile Number</Typography>
            <Typography variant="body1">+91 {userDetail?.mobile}</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Created Date</Typography>
            <Typography variant="body1">{moment(userDetail?.createdAt).format('DD-MM-YYYY')}</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Role</Typography>
            <Typography variant="body1">{userDetail?.role?.roleName}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName={"Close"}
          size="small"
          color="white"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  );
};

export default ViewUserModel;
