import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Link,
} from "@react-pdf/renderer";
import logoBase64 from "../../../assets/images/sidebar/PdfLogo.jpg"; 
import BackButton from "../../../components/common/Backbutton";
import CommonButton from "../../../components/common/Button";

// Create styles
const styles = StyleSheet.create({
  page: {
 
    padding:"20px 10px 10px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    flexDirection: "column",
    marginBottom: 10,
  },
  infoContainer: {
    marginLeft: 10,
    display:"flex",flexDirection:"column",gap:5
  },
  headerfont: {
    fontSize: 12,
    maxWidth: "250px",
  },
  separator: {
    width: "100%",
    height: 2,
    backgroundColor: "black",
  },
  body1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    width: "100%",
    gap: "50px",
  },
  leftsection_body1: {
    border: "1px solid black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "13px",
    alignItems: "center",
  },

  blackbox: {
    backgroundColor: "black",
    color: "white",
    padding: "8px",
  },
  whitebox: {
    backgroundColor: "white",
    color: "black",
    padding: "8px",
  },
  graybox: {
    backgroundColor: "#949494",
    color: "white",
    padding: "8px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 5,
    justifyContent: "flex-start",
  },
  body2: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    flexWrap: "wrap",
    marginBottom:"40px",
    fontSize: 10,
    paddingRight:"10px",
  },
  delaerfont: {
    fontWeight: 500,
    fontSize: 10,
    textTransform:"uppercase",
    paddingLeft: "10px",
  },
  dealerinput1: {
    borderBottom: "1px solid black",
    fontSize: 10,
    fontWeight: 600,
    width: "80%",
    marginLeft: "15px",
  },
  dealerinput2: {
    borderBottom: "1px solid black",
    fontSize: 10,
    fontWeight: 600,
    width: "80%",
    marginLeft: "10px",
  },
  row1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "50%",
    marginBottom: "8px",
    paddingBottom: "3px",
    gap: "5px",
  },
  tableContainer: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#cccccc",
    padding: 5,
    fontWeight: "bold",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
  },
  dealerfont: {
    fontSize: 11,
    fontWeight: 500,
  },
  blankinput: {
    width: "140px",
    border: "1px solid black",
    padding: "5px",

    fontSize: 11,
    display: "flex",
    alignItems: "center",
  },
  content: {
    flex: 1, 
  },
  footer: {
    marginTop: "auto", 
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px  0px 0px 0px",
  },
  // footer: {
  //   display: "flex",
  //   flexDirection: "column",
  //   gap: "10px",
  //   padding: "10px 10px 0px 10px",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   position: "relative",  // Use fixed instead of absolute
  //   bottom: 5,
  //   left: 0,
  //   right: 0,
  //   zIndex: 100,
  // },
  termstable: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#000',
  },
  termRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  termColHeader: {
    backgroundColor: '#cccccc',
    // padding: 5,
    alignItems: 'center',
    height: 35,
    justifyContent: 'center',
  },
  termCol: {
    flex: 1,
    padding: 5,
  },
  termCell: {
    fontSize: 11,
    textAlign: 'center',
  },
});
const data = [
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: "Remarks Remarks Remarks Remarks",
  },
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: "Remarks ",
  },

  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: " Remarks",
  },
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: " Remarks",
  },
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: "Remarks ",
  },
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: " Remarks",
  },
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: "Remarks ",
  },

  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: " Remarks",
  },
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: "Remarks ",
  },

  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: " Remarks",
  },
  {
    size: "1200x600",
    surface: "Glossy",
    premium: "50",
    remarks: " Remarks",
  },
];
const termdata = [
  {
    condition: "ABOVE PRICES ARE EX-FACTORY PRICES. ( I.E. EXCLUDING TAXES & INSURANCE )",
  },
  {
    condition: "GST AS ACTUAL.",
  },
  {
    condition: "PAYMENT TERMS AS PER THE COMPANY POLICY.",
  },
  {
    condition: "PRICES ARE SUBJECT TO CHANGE WITHOUT ANY PRIOR NOTICE. PRICE RULING AT THE TIME OF DISPATCH WILL BE CHARGED.",
  },
  {
    condition: "NO COMPLAINT WILL BE ENTERTAINED AFTER LAYING OF TILES.",
  },
  {
    condition: "2% BREKAGE IN TRANSIT SHOULD BE ACCEPTABLE BY CUSTOMER.",
  },
  {
    condition: "ABOVE 2% BREAKAGE IN TRANSIT TO BE INFORM IMMEDIATELY WITHIN 24 HOURS FOR INSURANCE CLAIM REGISTRATION.",
  },
  {
    condition: "INSURANCE CLAIM RE-IMBURSEMENT AMOUNT IS SUBJECT TO INSURANCE COMPANY, DEFICIT LOSS AMOUNT IS NOT OUR LIABILITY.",
  },
  {
    condition: "SUBJECT TO MORBI ( GUJARAT ) JURISDICTION.",
  },
  {
    condition: " OUR RESPONSIBILITIES CEASES AFTER THE DISPATCH OF GOODS FROM OUR PREMISES",
  },
  {
    condition: " ALL COMMUNICATIONS & COMMITMENTS MUST BE IN WRITTEN FORMAT ONLY, ORAL DISCUSSIONS WILL NOT BE ACCEPTED BY BOTH SIDES.",
  },
  
]
// Document Component
const Letterhead = () => (
  <Document>
    <Page style={styles.page} size="A4" wrap>
      {/* Header */}
      <View style={styles.header} fixed>
        <View style={{ flexDirection: "row", justifyContent: "space-between" ,alignItems:'flex-start',padding:"0px 0px 10px 0px"}}>
          <Image src={logoBase64} style={{ width: 150,}} />
          <View style={styles.infoContainer}>
            <Text style={styles.headerfont}>
              <Text style={{ fontWeight: 600 }}>GST: </Text> 221312331
            </Text>
            <Text style={styles.headerfont}>
              <Text style={{ fontWeight: 600 }}>Phone: </Text> 9895959795
            </Text>
          </View>
        </View>
        <View style={styles.separator} />
      </View>
      {/* Body */}
      <View style={styles.content}>
        <View style={styles.body1}>
          <View style={styles.leftsection_body1}>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.blackbox}>ZONE</Text>
              <Text style={styles.whitebox}>NORTH</Text>
            </View>
            <View>
              <Text style={styles.blackbox}>PRICE LIST</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.graybox}>SR NO.</Text>
              <Text style={styles.whitebox}>123465789</Text>
            </View>
          </View>
          <View style={styles.row}>
            <Text style={styles.delaerfont}>Date :</Text>
            <Text style={{ borderBottom: "1px solid black",fontSize: "10px",fontWeight: 600,marginRight:"10px",width:"80px"}}> 18-10-2024</Text>
          </View>
        </View>
        <View style={styles.body2}>
          {/* Firm Name */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>FIRM NAME :</Text>
            <Text style={styles.dealerinput1}>Mukesh Thoriya</Text>
          </View>

          {/* City */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>CITY :</Text>
            <Text style={{ borderBottom: "1px solid black",fontSize: "10px",fontWeight: 600,width: "100%",marginLeft: "10px",}}>Morbi</Text>
          </View>

          {/* Year of Establishment (YOE) */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>YOE :</Text>
            <Text style={{ borderBottom: "1px solid black",fontSize: "10px",fontWeight: 600,width: "100%",marginLeft: "10px",}}>2024</Text>
          </View>

          {/* Firm Address */}
          <View
            style={{flexDirection: "row",width: "100%",alignItems: "center",marginBottom: "8px", paddingBottom: "3px",gap: "5px",}}
          >
            <Text style={styles.delaerfont}>FIRM ADDRESS :</Text>
            <Text
              style={{width: "100%",borderBottom: "1px solid black",fontSize: "10px",fontWeight: 600,marginLeft: "15px"}}
            >
              707, Shyam Pelis, Shiv Shakti Park, Lilapar Road, Rajkot
            </Text>
          </View>

          {/* GST Number */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>GST NUMBER :</Text>
            <Text style={styles.dealerinput1}>1234567891234</Text>
          </View>

          {/* State */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>STATE :</Text>
            <Text style={{borderBottom: "1px solid black", fontSize: "10px",fontWeight: 600,width: "100%",marginLeft: "10px",}}>GUJARAT</Text>
          </View>

          {/* Person Name */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>PERSON NAME :</Text>
            <Text style={styles.dealerinput1}>Dhirav Patel Dhirav Patel Dhirav Patel Dhirav Patel</Text>
          </View>

          {/* Contact Number */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>CONTACT NO. :</Text>
            <Text style={styles.dealerinput1}>9876543210</Text>
          </View>

          {/* Party Reference */}
          <View
            style={{flexDirection: "row",width: "50%",marginVertical: 5,alignItems: "center",justifyContent: "flex-start",marginBottom: "8px",  paddingBottom: "3px",gap: "5px",
            }}
          >
            <Text style={styles.delaerfont}>PARTY REFERENCE :</Text>
            <Text
              style={{borderBottom: "1px solid black", fontSize: "10px",fontWeight: 600,width: "80%",marginLeft: "35px",}}
            >
              Nirav Patel
            </Text>
          </View>

          {/* Party Master */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>PARTY MASTER :</Text>
            <Text style={styles.dealerinput1}>Rajan Patel</Text>
          </View>

          {/* Scheme */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>SCHEME :</Text>
            <Text style={styles.dealerinput2}>NO</Text>
          </View>

          {/* Category */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>CATEGORY :</Text>
            <Text style={styles.dealerinput2}>OEM BRAND</Text>
          </View>

          {/* Credit Limit */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>CREDIT LIMIT :</Text>
            <Text style={styles.dealerinput1}>2</Text>
          </View>

          {/* Credit Days */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>CREDIT DAYS :</Text>
            <Text style={styles.dealerinput1}>13</Text>
          </View>

          {/* CD % for Premium */}
          <View style={{flexDirection: "row",width: "50%",marginVertical: 5,alignItems: "center",justifyContent: "flex-start",marginBottom: "8px",  paddingBottom: "3px",gap: "5px",}}
          >
            <Text style={styles.delaerfont}>CD % ONLY FOR PREMIUM :</Text>
            <Text
              style={{borderBottom: "1px solid black", fontSize: "10px",fontWeight: 600,width: "80%",marginLeft: "70px",}}>
              3
            </Text>
          </View>

          {/* Brand Visibility */}
          <View style={styles.row1}>
            <Text style={styles.delaerfont}>BRAND VISIBILITY :</Text>
            <Text style={{borderBottom: "1px solid black", fontSize: "10px",fontWeight: 600,width: "80%",marginLeft: "25px",}}>NO</Text>
          </View>

          {/* Sample Policy */}
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              marginVertical: 5,
              alignItems: "flex-end",
            }}
          >
            <Text style={styles.delaerfont}>SAMPLE POLICY :</Text>
            <Text
              style={{
                width: "100%",
                borderBottom: "1px solid black",
                fontSize: "10",
                fontWeight: 600,
                marginLeft: "20px",
              }}
            ></Text>
          </View>
        </View>
        <View style={styles.tableContainer} >
          <View>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Size</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Surface</Text>
              </View>
              <View style={{  width: "15%", borderStyle: "solid",borderWidth: 1,borderLeftWidth: 0,borderTopWidth: 0,backgroundColor: "#cccccc", padding: 5, fontWeight: "bold",}}>
                <Text style={styles.tableCell}>Premium</Text>
              </View>

              <View style={{  width: "35%", borderStyle: "solid",borderWidth: 1,borderLeftWidth: 0,borderTopWidth: 0,backgroundColor: "#cccccc", padding: 5, fontWeight: "bold",}}>
                <Text style={styles.tableCell}>REMARKS</Text>
              </View>
            </View>

            {/* Table Rows */}
            {data.map((row, index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.size}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.surface}</Text>
                </View>
                <View style={{  width: "15%", borderStyle: "solid",borderWidth: 1,borderLeftWidth: 0,borderTopWidth: 0, padding: 5, fontWeight: "bold",}}>
                  <Text style={styles.tableCell}>{row.premium}</Text>
                </View>

                <View style={{  width: "35%", borderStyle: "solid",borderWidth: 1,borderLeftWidth: 0,borderTopWidth: 0, padding: 5, fontWeight: "bold",}}>
                  <Text style={styles.tableCell}>{row.remarks}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            padding: "25px 0px",
          }}
        >
          <View
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexDirection: "row",
            }}
          >
            <Text style={styles.dealerfont}> SECURITY CHEQUE NUMBER :</Text>
            <Text style={styles.blankinput}> 4568 5465 2358 988</Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexDirection: "row",
            }}
          >
            <Text style={styles.dealerfont}> BANK NAME :</Text>
            <Text style={styles.blankinput}> Kotak Mahindra Bank </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "flex-end",
          }}
        >
          <Text style={styles.dealerfont}>NOTE :</Text>
          <Text
            style={{
              width: "100%",
              borderBottom: "1px solid black",
              fontSize: "10px",
              fontWeight: 600,
            }}
          ></Text>
        </View>
        {/* terms and condition table */}
        <View style={styles.termstable} break >
          <View>
              <View style={styles.termColHeader}>
                <Text style={styles.termCell}>TERMS & CONDITIONS</Text>
              </View>
            {termdata.map((row, index) => (
              <View key={index} style={{display:'flex',flexDirection:"row",borderBottom:"1px"}}>
                <View style={{width:"50px",display:"flex",alignItems:"center",justifyContent:'center',borderRight:"1px solid black"}}>
                  <Text style={styles.termCell}>{index+1}</Text>
                </View>
                <View style={styles.termCol}>
                  <Text style={{   fontSize: 8,textAlign: 'left',color:"#808080",lineHeight:"1.3px"}}>{row.condition}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={{display: "flex",flexDirection: "row",gap: "10px",justifyContent:"space-evenly",margin:"30px 0px"}}>
          <View>
            <Text style={styles.dealerfont}>  DEALER SIGNATURE / STAMP </Text>
          </View>
          <View >
            <Text style={styles.dealerfont}>  DIRECTOR / SALES HEAD SIGNATURE </Text>
          </View>
        </View>
      </View>
      {/* footer */}
        <View style={styles.footer} fixed>
          <View style={styles.separator} />
          <Text style={{ fontWeight: 600, fontSize: 11 ,padding:"10px 0px 5px 0px"}}>
            306, Eden Ceramic City, Opp. Hotel Ravi Residency, NH 8A, Lalpar,
            Morbi-363642, Gujarat
          </Text>
          <Text style={{ fontWeight: 600, fontSize: 11  }}>
            <Link
              style={{ color: "black", textDecoration:"none" }}
              href="mailto:info@asiapacificceramic.com"
            >
              info@asiapacificceramic.com
            </Link>{" "}
            | &nbsp;
            <Link
              style={{ color: "black",textDecoration:"none" }}
              href="https://www.asiapacificceramic.com/en"
            >
              www.asiapacificceramic.com
            </Link>
          </Text>
        </View>
    </Page>
  </Document>
);

const LetterheadPDF = () => (
  <div style={{ padding: "20px", textAlign: "center" }}>
    <BackButton />
    <h2>Download Your Letterhead PDF</h2>
    <PDFDownloadLink document={<Letterhead />} fileName="letterhead.pdf">
      {({ blob, url, loading, error }) => {
        if (loading) return "Loading document...";
        if (error) {
          console.error("Error generating PDF:", error);
          return "Error generating PDF";
        }
        return <CommonButton buttonName="Download" color="white" />;
      }}
    </PDFDownloadLink>
    <PDFViewer style={{ width: "100%", height: "600px" }}>
      <Letterhead />
    </PDFViewer>
  </div>
);

export default LetterheadPDF;
