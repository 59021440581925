import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Box,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { FaArrowDown, FaArrowUp, FaEdit, FaEye } from "react-icons/fa";
import CommonModal from "../../../components/common/Modal";
import TableRowsLoader from "../../../components/common/Loader/Skeleton";
import TableSearch from "../../../components/common/Table/TableSearch";
import Heading from "../../../components/Heading";
import CommonButton from "../../../components/common/Button";
import { DeleteIcon } from "../../../assets/icons/Icons";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import Pagination from "../../../components/common/Table/Pagination";
import { deleteTaxConfig, statusChangeTaxConfig, taxConfigList } from "../../../Service/taxconfig.service";


const data = [
  {
    id: 1,
    name: "CGST",
    value: "5.00",
    label: "CGST ( 5 % )",
    date:"29-05-2024"
  },
  {
    id: 2,
    name: "GST",
    value: "10.00",
    label: "IGST",
    date:"29-09-2024"

  },
];

const TaxList = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    id: "",
  });
  const dispatch = useDispatch();
  const [taxConfigData, setTaxConfigData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getTaxConfigList = async () => {
    setTaxConfigData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await taxConfigList(query);
    if (res?.status) {
      setTaxConfigData(res?.data);
      setTotalData(res?.total);
    } else {
      setTaxConfigData(null);
    }
  }
  
  useEffect(() => {
    getTaxConfigList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

 
  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == 'status' ? await statusChangeTaxConfig(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await deleteTaxConfig(isModalOpen?.id) : null;
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getTaxConfigList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message:  'Something went wrong. Please try again later.'
      }))
    }
  }

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };

  return (
    <>
      <Stack gap={2}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={1}
          gap={2}
        >
          <Heading head={"Tax Config"} />
        </Stack>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
            <CommonButton
              buttonName={"Create"}
              size="small"
              onClick={() =>
                setIsModalOpen({
                  open: true,
                  currentComponent: "Addtextconfig",
                })
              }
            />
          </Stack>
        </Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow style={{ cursor: "pointer" }}>
                <TableCell> {"Name"} </TableCell>
                <TableCell> {"Value"} </TableCell>
                <TableCell>{"Lable"} </TableCell>
                <TableCell onClick={() => handleSortClick('createdAt', sortDate)}>Created Date
                  {sortDate ? <FaArrowUp style={{ color: '#adadad' }} /> : <FaArrowDown style={{ color: '#adadad' }} />}
                  </TableCell>
                <TableCell>{"Status"}</TableCell>
                <TableCell sx={{textAlign:'end'}}>{"Action"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taxConfigData && taxConfigData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.taxName}</TableCell>
                  <TableCell>{row.taxValue}</TableCell>
                  <TableCell>{row.taxLabel}</TableCell>
                  <TableCell>{moment(row?.createdAt).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>
                      <Switch
                        checked={row?.status === 1 ? true : false}
                        onChange={(e) => {
                          e.stopPropagation();
                          setIsModalOpen({ id: row?.id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                        }}
                      />
                    </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                       <Tooltip title="view">
                        <IconButton
                          size="small"
                          onClick={() =>
                            setIsModalOpen({
                              open: true,
                              currentComponent: "Addtextconfig",
                              id: row.id,
                            })
                          }
                        >
                          <FaEdit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={() => {
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "delete",
                                  id: row.id,
                                head: "Tax-Config Delete", para: "Are you sure you want to Delete the Tax-Config?" 

                                });
                              }}
                        >
                          <MdDelete />
                        </IconButton>
                      </Tooltip>
                     
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
     {taxConfigData !== null && (

        <Pagination totalRecords={totalData}
          data = {taxConfigData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />)}
      <CommonModal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "",id: "" });
          getTaxConfigList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default TaxList;
