import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FiSearch } from "react-icons/fi";
import { InputBase } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '4px 15px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: '20ch',
    },
    [theme.breakpoints.up('lg')]: {
        width: '30ch',
    },
    '&:hover': {
        backgroundColor: '#fff',
    },
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    position: 'absolute',
    top: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing('7px', 1, '6px', 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        },
        [theme.breakpoints.up('lg')]: {
            width: '30ch',
        },
    },
}));

const TableSearch = (props) => {
    const {  value, setSearch, setPage } = props
    return (
        <Search>
            <SearchIconWrapper>
                <FiSearch style={{ fontSize: '22px', color: '#A4A4A4' }} />
            </SearchIconWrapper>
            <StyledInputBase
                value={value}
                placeholder="Search here..."
                {...props}
                inputProps={{ 'aria-label': 'search' }}
                 onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(0);
                    }}
            />
        </Search>
    )
}

export default TableSearch