import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CommonButton from "../Button";
import { LogoutIcon } from "../../../assets/icons/Icons";

const UpdateStatus = ({ handleClose, handelClick }) => {
  return (
    <>
      <>
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent dividers sx={{ maxWidth: "500px", width: "100%" }}>
          <Typography
            className="myprofile_input_label"
            sx={{ paddingBottom: "2px" }}
          >
            Are You Sure you want to Update Status ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CommonButton onClick={handleClose} buttonName="No" />
          <CommonButton onClick={handelClick} buttonName="Yes" />
        </DialogActions>
      </>
    </>
  );
};

export default UpdateStatus;
