import React, { useEffect } from "react";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CommonButton from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import { CloseIcon } from "../../../helper/Icons";
import { AddSize, getSizeById, updateSize } from "../../../Service/size.service";
import { addSizeInitialValues } from "../../../helper/initialValues";
import { sizeValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";

const AddSize_modal = ({ modalOpen, handleClose, handelClick }) => {
  const id = modalOpen.id;

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

    const formik = useFormik({
    initialValues: addSizeInitialValues,
    onSubmit: async (value) => {
      setLoading(true);
      const payload = {
        sizeName: value.sizeName,
      };

      const res = id ? await updateSize(id, payload) : await AddSize(payload);
      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Size Added Successfully.'
        }
        ))
        handleClose();
      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: sizeValidationSchema,
    });
  
  const getSizeDetail = async () => {
    const res = await getSizeById(id);
    if (res.status) {
      formik.setFieldValue("sizeName", res.data.sizeName);
    }
  }

  useEffect(() => {
    if (id) {
      getSizeDetail();
    }
  }, [])
  

  console.log("formik", formik);
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        <Typography variant="h6">
          {id ? "Edit Size" : "Add Size"}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth: "500px", width: "100%" }}>
        <Grid container spacing={2} minWidth={{ xs: "100%", sm: "400px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Size "} type="text"
              maxLength={50}
              {...formik.getFieldProps("sizeName")}
              error={formik.touched.sizeName && formik.errors.sizeName}
              helperText={formik.touched.sizeName && formik.errors.sizeName}
              
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <FormControl
              fullWidth
              variant="standard"
              sx={{ marginTop: "-6px" }}
            >
              <Autocomplete
                options={groups}
                multiple
                renderInput={(params) => (
                  <Input {...params} labelinput={"Surface"} />
                )}
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton
          buttonName="Save"
          size="small"
          color="primary"
          onClick={
            formik.handleSubmit
          } 
        />
      </DialogActions>
    </>
  );
};

export default AddSize_modal;
