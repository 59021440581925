import { Description } from '@mui/icons-material';
import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required'),
});

export const sizeValidationSchema = Yup.object().shape({
  sizeName: Yup.string().required('Size Name is required'),
});

export const surfaceValidationSchema = Yup.object().shape({
  surfaceName: Yup.string().required('Surface Name is required'),
  surfaceDetail: Yup.string().required('Surface Detail is required'),
});

export const categoryValidationSchema = Yup.object().shape({
  categoryName: Yup.string().required('Category Name is required'),
});

export const taxConfigValidationSchema = Yup.object().shape({
  taxName: Yup.string().required('Name is required'),
  taxLabel: Yup.string().required('Label is required'),
  taxValue: Yup.string().required('Tax Value is required'),
  description: Yup.string().optional(),
});

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.string().required('Mobile Number is required'),
  role: Yup.number().required('Role is required'),
});


export const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
  oldPassword: Yup.string()
    .required('Current password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
  confirmpass: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Confirm passwords same as newpassword')
    .required('Confirm password is required'),
});
export const addAdminValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  role: Yup.string().required('Role is required.'),
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  zipCode: Yup.string().optional()
    .matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ),
});

export const changePasswordValidationSchemas = Yup.object().shape({
  newPassword: Yup.string()
    .required('New Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
  confirmpass: Yup.string()
    .required('Confirm Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
  currentPassword: Yup.string().required('Password is required.'),
});
export const roleAddValidationSchema = Yup.object().shape({
  roleName: Yup.string().required('Role Name is required.'),
});


export const dealerValidationSchema = Yup.object().shape({
  zone: Yup.string().optional(),
  srNo: Yup.string().optional(),
  // date: Yup.date().optional(),
  gstNuumber: Yup.string()
    .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/, 'GST Number must be in the correct format')
    .required('GST Number is required'),
  panNumber: Yup.string().required('PAN Number is required'),
  authPersonName: Yup.string().required('Authorized Person Name is required'),
  firmName: Yup.string().required('Firm Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  firmAddress: Yup.string().required('Firm Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pincode must be 6 digits')
    .required('Pincode is required'),
  personName: Yup.string().optional(),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
    .required('Mobile number is required'),
  partyReference: Yup.string().optional(),
  salesRepresentative: Yup.string().optional(),
  categoryId: Yup.number().optional(),
  creditLimit: Yup.string().optional().matches(/^[0-9]*$/, 'Credit Limit must be numeric.'),
  creditDays: Yup.string().optional().matches(/^[0-9]*$/, 'Credit Days must be numeric.'),
  cdPer: Yup.number().min(0, 'CD Percentage cannot be negative'),
  samplePolicy: Yup.string().optional(),
  sequrityCheque: Yup.string().optional(),
  bankName: Yup.string().optional(),
  brandVisible: Yup.boolean().optional(),
});

export const dealerProductValidationShema = Yup.object().shape({
  dealerProducts: Yup.array().of(
    Yup.object().shape({
      size: Yup.string().required('Size is required'),
      surface: Yup.string().required('Surface is required'),
      rate: Yup.string().optional().
        matches(/^\d{0,10}(\.\d{0,2})?$/, 'Rate must be decimal with 2 precision'),
      remark: Yup.string().optional(),
    })
  ),
});

export const addDealerValidationSchema = Yup.object().shape({
  zone: Yup.string().optional(),
  srNo: Yup.string().optional(),
  // date: Yup.date().optional(),
  gstNuumber: Yup.string()
    .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/, 'GST Number must be in the correct format')
    .required('GST Number is required'),
  panNumber: Yup.string().required('PAN Number is required'),
  authPersonName: Yup.string().required('Authorized Person Name is required'),
  firmName: Yup.string().required('Firm Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  firmAddress: Yup.string().required('Firm Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pincode must be 6 digits')
    .required('Pincode is required'),
  personName: Yup.string().optional(),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
    .required('Mobile number is required'),
  partyReference: Yup.string().optional(),
  salesRepresentative: Yup.string().optional(),
  categoryId: Yup.number().optional(),
  creditLimit: Yup.string().optional().matches(/^[0-9]*$/, 'Credit Limit must be numeric.'),
  creditDays: Yup.string().optional().matches(/^[0-9]*$/, 'Credit Days must be numeric.'),
  cdPer: Yup.number().min(0, 'CD Percentage cannot be negative'),
  samplePolicy: Yup.string().optional(),
  sequrityCheque: Yup.string().optional(),
  bankName: Yup.string().optional(),
  brandVisible: Yup.boolean().optional(),
  note: Yup.string().optional(),
  dealerProducts: Yup.array().of(
    Yup.object().shape({
      size: Yup.number().required('Size is required'),
      surface: Yup.number().optional(),
      rate: Yup.number().min(0, 'Rate cannot be negative').optional(),
      remark: Yup.string().optional(),
    })
  ),
});


export const addSampleRequestValidationSchema = Yup.object().shape({
  dealerId: Yup.number().optional(),
  gstNumber: Yup.string().optional(),
  // date: Yup.date().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pincode must be 6 digits')
    .optional(),
  categoryId: Yup.number().optional(),
  roughPads: Yup.string().optional()
    .matches(/^[0-9]*$/, 'Rough Pads must be numeric.'),
  penPocket: Yup.string().optional()
    .matches(/^[0-9]*$/, 'Pen Pocket must be numeric.'),
  // loadingDate: Yup.date().optional(),
  // processStartDate: Yup.date().optional(),
  // materialReadyDate: Yup.date().optional(),
  desplaySize: Yup.string().optional(),
  notes: Yup.string().optional(),
  sampleProducts: Yup.array().required('Pleae add sample products'),
});

export const addSampleProductValidationSchema = Yup.object().shape({
  sizeId: Yup.number().required('Size is required'),
  surfaceId: Yup.number().required('Surface is required'),
  designId: Yup.number().required('Design is required'),
  sample: Yup.string().required('Sample is required'),
  preview: Yup.number().required('Preview is required'),
  qr: Yup.number().required('QR is required'),
  smallPCS: Yup.number().required('Small PCS is required')
});

export const addSampleValidationSchema = Yup.object().shape({
  dealerId: Yup.number().optional(),
  gstNumber: Yup.string().optional(),
  // date: Yup.date().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pincode must be 6 digits')
    .optional(),
  categoryId: Yup.number().optional(),
  roughPads: Yup.string().optional()
    .matches(/^[0-9]*$/, 'Rough Pads must be numeric.'),
  penPocket: Yup.string().optional()
    .matches(/^[0-9]*$/, 'Pen Pocket must be numeric.'),
  // loadingDate: Yup.date().optional(),
  // processStartDate: Yup.date().optional(),
  // materialReadyDate: Yup.date().optional(),
  desplaySize: Yup.string().optional(),
  notes: Yup.string().optional(),
});

export const addProductValidationSchema = Yup.object().shape({
  productName: Yup.string().required('Product Name is required'),
  surface: Yup.number().required('Surface is required'),
  size: Yup.number().required('Size is required'),
  price: Yup.number().required('Price is required'),
  description: Yup.string().required('Description is required'),
  tax: Yup.number().required('Tax is required'),
  salePrice: Yup.number().required('Sales Price is required'),
  specification: Yup.string(),
});