import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/Heading";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import photo from "../../assets/images/tiles.jfif";
import { DealerDetail, DealerVersionDropdown } from "../../Service/dealer.service";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";


const View_dealer = () => {
  const navigate = useNavigate();
  const [dealerData, setDealerData] = React.useState({});
  const [versionData, setVersionData] = React.useState([]);
  const { state } = useLocation();
  console.log(state);

  const [version, setVersion] = useState("");

  const handleVersionChange = (event) => {
    // if (state && state?.index) {
    //   setVersion(state.index);
    // } else {
    setVersion(event.target.value);
    getDealerData(state.id, event.target.value);
    // }
  };

  console.log("versionversionversionversionversion", state, version, "");
  useEffect(() => {
    console.log("versionversionversionversionversion", version);
    if (state && state?.id) {
      getDealerData(state.id, state.index);
      if (version == "") {
        getVersionData(state.id);
      }

    }
  }, [state, version]);

  const getDealerData = async (id, version) => {
    let res = await DealerDetail(id, version);
    if (res?.status) {
      setDealerData(res.data);
    } else {
      setDealerData({});
    }
  }

  const getVersionData = async (id) => {
    let res = await DealerVersionDropdown(id);
    if (res?.status) {
      setVersionData(res.data);
      if (res.data[0]?.index && version == "") {
        setVersion(res.data[0]?.index);
      } else {
        setVersion("");
      }
    }
  };
  return (
    <>
      <Stack gap={2}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
          >
            <BackButton />
            <Heading head={dealerData?.authPersonName} />
          </Box>

          <Box>
            <CommonButton
              buttonName="Edit"
              color="secondary"
              onClick={() => navigate("/dealer-form", { state: { id: state?.id }, isEdit: true })}
            />

          </Box>
          <Box>
            <FormControl fullWidth>
              <Select
                value={version}
                onChange={handleVersionChange}
                sx={{
                  fontSize: "18px !important",
                  color: "#E54E09 !important",
                  backgroundColor: "#F6F8F9 ",
                  borderRadius: "5px !important",
                  fontWeight: 800,
                }}
              >
                {versionData?.map((data, i) => (
                  <MenuItem key={i} value={data.index}>
                    {data.version}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Name :</Typography>
                <Typography>{dealerData?.personName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Mobile Number :</Typography>
                <Typography>+91 {dealerData?.mobile}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Email :</Typography>
                <Typography>{dealerData?.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Authorized Person Name :</Typography>
                <Typography>{dealerData?.authPersonName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>City :</Typography>
                <Typography>{dealerData?.city}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>State :</Typography>
                <Typography>{dealerData?.state}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Zipcode :</Typography>
                <Typography>{dealerData?.pincode}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>SR NO. :</Typography>
                <Typography>{dealerData?.srNo}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Date :</Typography>
                <Typography>{moment(dealerData?.date).format('YYYY/MM/DD')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>GST Number :</Typography>
                <Typography>{dealerData?.gstNuumber}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Pan Number :</Typography>
                <Typography>{dealerData?.panNumber}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Firm Name :</Typography>
                <Typography>{dealerData?.firmName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Firm Address :</Typography>
                <Typography sx={{}}>
                  {dealerData?.firmAddress}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Party Reference :</Typography>
                <Typography>{dealerData?.partyReference}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Sales Representative :</Typography>
                <Typography>{dealerData?.salesRepresentative?.firstName} {dealerData?.salesRepresentative?.lastName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Category :</Typography>
                <Typography>{dealerData?.categoryId?.categoryName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Credit Limit :</Typography>
                <Typography>{dealerData?.creditLimit}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Credit Days :</Typography>
                <Typography>{dealerData?.creditDays}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>CD % Only For Premium :</Typography>
                <Typography>{dealerData?.cdPer}%</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Brand Visibility :</Typography>
                <Typography>{dealerData?.brandVisible === 1 ? 'Yes' : 'No'}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Security Cheque Number :</Typography>
                <Typography>{dealerData?.sequrityCheque}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Bank Name :</Typography>
                <Typography>{dealerData?.bankName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography sx={{ width: "fit-content !important" }}>
                  Sample Policy :
                </Typography>
                <Typography>{dealerData?.samplePolicy}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>Surface</TableCell>
                <TableCell>Premium Rate</TableCell>
                <TableCell>Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealerData?.dealerProducts?.map((item, index) => (
                // item.surfaces.map((surface, subIndex) => (
                <TableRow
                  // key={`${index}-${subIndex}`}
                  sx={{
                    "&:hover": {
                      boxShadow: "none !important",
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  {/* {subIndex === 0 && (
                      <TableCell rowSpan={item.surfaces.length}>
                        {item.size}
                      </TableCell>
                    )} */}
                  <TableCell>{item.size?.sizeName}</TableCell>
                  <TableCell>{item.surface?.surfaceName}</TableCell>
                  <TableCell>{item.rate}</TableCell>
                  <TableCell>{item.remark}</TableCell>
                </TableRow>
                // )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box>
                <Typography fontWeight={800}>Notes :</Typography>
                <Box>
                  <Typography>
                    {dealerData?.dealerNotes?.note}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography fontWeight={800}>Cheque Image :</Typography>

            <img
              src={dealerData?.chequeImage}
              alt="Cheque image"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: '300px',
                borderRadius: "10px",
                objectFit: "contain",
              }}
            />
          </Paper>
        </Grid>
      </Stack>
    </>
  );
};

export default View_dealer;
