import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CommonButton from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import { CloseIcon } from "../../../helper/Icons";
import { addCategory, getCategoryById, updateCategory } from "../../../Service/category.service";
import { addCategoryInitialValues } from "../../../helper/initialValues";
import { categoryValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useEffect } from "react";

const AddCategory = ({ modalOpen, handleClose, handelClick }) => {
    const id = modalOpen.id;

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

    const formik = useFormik({
    initialValues: addCategoryInitialValues,
    onSubmit: async (value) => {
      setLoading(true);
      const payload = {
        categoryName: value.categoryName,
      };

      const res = id ? await updateCategory(id, payload) : await addCategory(payload);
      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Size Added Successfully.'
        }
        ))
        handleClose();
      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: categoryValidationSchema,
    });
  
  const getCategoryDetail = async () => {
    const res = await getCategoryById(id);
    if (res.status) {
      formik.setFieldValue("categoryName", res.data.categoryName);
    }
  }

  useEffect(() => {
    if (id) {
      getCategoryDetail();
    }
  }, [])
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        <Typography variant="h6">{id ? "Edit Category" : "Add Category"}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth: "500px", width: "100%" }}>
        <Grid container spacing={2} minWidth={{ xs:"100%",sm: "400px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Category Name"}
              type="text" maxLength={50}
              {...formik.getFieldProps('categoryName')}
              error={formik.touched.categoryName && formik.errors.categoryName}
              helperText={formik.touched.categoryName && formik.errors.categoryName}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton
          buttonName="Save"
          size="small"
          color="primary"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddCategory;
