import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import Heading from "../../components/Heading";
import TableSearch from "../../components/common/Table/TableSearch";
import CommonButton from "../../components/common/Button";
import { FaEye } from "react-icons/fa";
import Input from "../../components/common/Input";
import { useNavigate } from "react-router-dom";

const Sales_Order = () => {
    const navigate = useNavigate();
  return (
    <>
      <Stack gap={2}>
        <Box>
          <Heading head="Sales Order" />
        </Box>
        <Box
          sx={{ flexGrow: 1 }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <TableSearch />
          <CommonButton buttonName="Create" onClick={()=> navigate('/create-sales-order')}/>
        </Box>
        <Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Shippement Status</TableCell>
                  <TableCell>Payable Amount</TableCell>
                  <TableCell>Sale Date</TableCell>
                  <TableCell>Order Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell> SO-2024-05-29-9353</TableCell>
                  <TableCell>John Doe</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select variant="outlined" defaultValue="Pending">
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Shipped">Shipped</MenuItem>
                        <MenuItem value="Delivered">Packed</MenuItem>
                      </Select>
                    </FormControl>                    
                  </TableCell>
                  <TableCell>1000</TableCell>
                  <TableCell>31-05-2024</TableCell>
                  <TableCell>
                    <Chip label="Pending" color="warning" />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View">
                      <IconButton size="small">
                        <FaEye />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  );
};

export default Sales_Order;
