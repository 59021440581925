import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import BackButton from "../../../components/common/Backbutton";
import Heading from "../../../components/Heading";
import { Tab } from "bootstrap";

const View_inventory_Adjust = () => {
  return (
    <>
      <Stack gap={2}>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent="flex-start"
          gap={1}
          alignItems="center"
          mb={2}
        >
          <BackButton />
          <Heading head="View Inventory Adjustment" />
        </Box>
        <Box className="border_card" p={2} gap={3}>
          <Stack gap={2}>
            <Box className="viewdetails">
              <Typography fontWeight={600} minWidth={"150px !important"}>
                Date:
              </Typography>
              <Typography>10-10-2024</Typography>
            </Box>
            <Box className="viewdetails">
              <Typography fontWeight={600} minWidth={"150px !important"}>
                Created by:
              </Typography>
              <Typography>admin</Typography>
            </Box>
            <Box className="viewdetails">
              <Typography fontWeight={600} minWidth={"150px !important"}>
                Reason:
              </Typography>
              <Typography>
                Lorem ipsum reedfd sjdwklas suoiwwen weiowei
              </Typography>
            </Box>
          </Stack>
          <Stack mt={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Quantity Adjusted</TableCell>
                    <TableCell>Cost Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Product 1</TableCell>
                    <TableCell>10</TableCell>
                    <TableCell>100</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Product 2</TableCell>
                    <TableCell>20</TableCell>
                    <TableCell>200</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default View_inventory_Adjust;
