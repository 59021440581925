import React, { useState } from "react";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  IconButton,
  Tooltip,
  Tab,
  Switch,
} from "@mui/material";
import Heading from "../../../components/Heading";
import CommonButton from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import { FaEdit, FaEye } from "react-icons/fa";
import TableSearch from "../../../components/common/Table/TableSearch";
import { MdDelete } from "react-icons/md";
import { categoryList, deleteCategory, statusChangeCategory } from "../../../Service/category.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import Pagination from "../../../components/common/Table/Pagination";
import { useEffect } from "react";
import moment from "moment/moment";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

const Category = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const dispatch = useDispatch();
  const [categoryData, setCategoryData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getCategoryList = async () => {
    setCategoryData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await categoryList(query);
    if (res?.status) {
      setCategoryData(res?.data);
      setTotalData(res?.total);
    } else {
      setCategoryData(null);
    }
  }
  
  useEffect(() => {
    getCategoryList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

 
  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == 'status' ? await statusChangeCategory(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await deleteCategory(isModalOpen?.id) : null;
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getCategoryList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message:  'Something went wrong. Please try again later.'
      }))
    }
  }

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };
  
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Categories"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          <CommonButton
            buttonName="Add Categrory"
            onClick={() => {
              setIsModalOpen({
                open: true,
                currentComponent: "AddCategory",
              });
            }}
          />
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SR</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell onClick={() => handleSortClick('createdAt', sortDate)}>Created Date
                {sortDate ? <FaArrowUp style={{ color: '#adadad' }} /> : <FaArrowDown style={{ color: '#adadad' }} />}
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryData && categoryData.map((row,index) => (
                  <TableRow key={row.id} sx={{cursor:"pointer"}}>
                    <TableCell>{index +1 }</TableCell>

                    <TableCell>{row.categoryName}</TableCell>
                    <TableCell>
                      {moment(row?.createdAt).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={row?.status === 1 ? true : false}
                        onChange={(e) => {
                          e.stopPropagation();
                          setIsModalOpen({ id: row?.id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        
                        <Tooltip title="Edit Details">
                          <IconButton
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "AddCategory",
                                id: row.id,

                              });
                            }}
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>

                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "delete",
                                 id: row.id,
                                head: "Category Delete", para: "Are you sure you want to Delete the Category?" 

                              });
                            }
                            }
                          >
                            <MdDelete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
      {categoryData !== null && (

        <Pagination totalRecords={totalData}
          data = {categoryData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />)}
       <CommonModal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "",id: "" });
          getCategoryList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Category;
