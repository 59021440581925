import React from "react";
import { useEffect } from "react";
import {
  Avatar,
  Box,
  Grid2,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import photo from "../../../assets/images/tiles.jfif";
import Heading from "../../../components/Heading";
import BackButton from "../../../components/common/Backbutton";
import { getProductById } from "../../../Service/product.service";
import { useLocation } from "react-router-dom";

const ViewProduct = () => {
  const [productData, setProductData] = React.useState({});
  const { state } = useLocation();

  useEffect(() => {
    if (state && state?.id) {
      getProductData(state.id);
         }
  }, [state]);

  const getProductData = async (id) => {
    let res = await getProductById(id);
    if (res?.status) {
      setProductData(res.data);
    } else {
      setProductData({});
    }
  }


  return (
    <Stack gap={2}>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
      >
        <BackButton />
        <Heading head="Product Details" />
      </Box>
      <Stack>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} sm={4}>
              <img
                src={productData?.productImage}
                alt="product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </Grid2>
            <Grid2 item xs={12} sm={7}>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"column"}
                gap={1}
              >
                <Grid2 item xs={12}>
                  <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                    {productData?.productName}
                  </Typography>
                </Grid2>
                <Grid2 item xs={12}>
                  <Stack className="viewdetails">
                    <Typography fontWeight={400}>Surface:</Typography>
                    <Typography>{productData?.surface?.surfaceName}</Typography>
                  </Stack>
                </Grid2>
                <Grid2 item xs={12}>
                  <Stack className="viewdetails">
                    <Typography fontWeight={400}>Size:</Typography>
                    <Typography>{productData?.size?.sizeName}</Typography>
                  </Stack>
                </Grid2>
                <Grid2 item xs={12}>
                  <Box display={"flex"} gap={2}>
                    <Grid2 item xs={6}>
                      <Stack className="viewdetails">
                        <Typography fontWeight={400}>Sale Price:</Typography>
                        <Typography>₹{productData?.salePrice}</Typography>
                      </Stack>
                    </Grid2>
                  </Box>
                </Grid2>
                <Grid2 item xs={12}>
                  <Stack className="viewdetails">
                    <Typography fontWeight={400}>Tax:</Typography>
                    <Typography>{productData?.tax?.taxValue}</Typography>
                  </Stack>
                </Grid2>
                <Grid2 item xs={12}>
                  <Stack className="viewdetails" sx={{ alignItems: "start" }}>
                    <Typography fontWeight={400}>Description:</Typography>
                    <Typography maxWidth={{ xs: "100%", sm: "450px" }}>
                      {productData?.description}{" "}
                    </Typography>
                  </Stack>
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>
          <Grid2 item xs={12} mt={2}>
            <Typography fontSize={"16px"} fontWeight={"600"} mb={1}>
              Specifications
            </Typography>
            <Table
              stickyHeader
              aria-label="simple table"
              sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="specification_table">
                {productData?.specification?.map((spec, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{spec.key}</TableCell>
                    <TableCell>{spec.value}</TableCell>
                  </TableRow>
                ))}
                
              </TableBody>
            </Table>
          </Grid2>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default ViewProduct;
