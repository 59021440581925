import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import Input from "../../components/common/Input";
import CommonButton from "../../components/common/Button";
import BackButton from "../../components/common/Backbutton";
import FileUpload from "../../components/common/FileUpload";
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import { salesRepresentativeDropdown } from "../../Service/user.service";
import { categoryDropdown } from "../../Service/category.service";
import FileUpload1 from "../../components/common/FileUpload1";
import Fileupload2 from "../../components/common/Fileupload2";
import Fileupload3 from "../../components/common/Fileupload3";

const zones = [
  { name: "East", id: 1 },
  { name: "West", id: 2 },
  { name: "North", id: 3 },
  { name: "South", id: 4 },
];

const Party_wise = ({ onNext, isEdit, setShow1, formik, files, setFiles, files1, setFiles1, files2, setFiles2, versionData, version, setVersion, deleteImage, setDeleteImage }) => {

  const [isNextEnabled, setIsNextEnabled] = React.useState(false);
  const [representativeData, setRepresentativeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [note, setValue] = useState("");

  function onChange(e) {
    setValue(e.target.value);
    formik.setFieldValue('note', e.target.value);
  }

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };


  const getDropdownData = async () => {
    let res = await salesRepresentativeDropdown();
    if (res?.status) {
      setRepresentativeData(res.data);
    } else {
      setRepresentativeData([]);
    }
  };

  const getCategoryDropdownData = async () => {
    let res = await categoryDropdown();
    if (res?.status) {
      setCategoryData(res.data);
    } else {
      setCategoryData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
    getCategoryDropdownData();

  }, []);

  return (
    <>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <BackButton />
            <Heading head={"Dealer Form"} />
          </Box>
          <Box>
            <FormControl fullWidth>
              <Select
                value={version}
                onChange={handleVersionChange}
                disabled={!isEdit}
                sx={{
                  fontSize: "18px !important",
                  color: "#E54E09 !important",
                  backgroundColor: "#F6F8F9 ",
                  borderRadius: "5px !important",
                  fontWeight: 800,
                }}
              >
                {versionData?.map((data, i) => (
                  <MenuItem key={i} value={data.index}>
                    {data.version}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className="form_border">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>Zone</Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{ marginTop: "-6px" }}
              >
                <Autocomplete
                  options={zones}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    zones.find((data) => data.id === formik.values.zone) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('zone', newValue ? newValue.id : '');
                  }}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      error={formik.touched.zone && Boolean(formik.errors.zone)}
                      helperText={formik.touched.zone && formik.errors.zone}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"SR NO."} type="text"
                name="srNo"
                {...formik.getFieldProps('srNo')}
                error={formik.touched.srNo && Boolean(formik.errors.srNo)}
                helperText={formik.touched.srNo && formik.errors.srNo}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Date"} type="date"
                name="date"
                {...formik.getFieldProps('date')}
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input labelinput={"GST Number"} type="text"
                name="gstNuumber"
                {...formik.getFieldProps('gstNuumber')}
                error={formik.touched.gstNuumber && Boolean(formik.errors.gstNuumber)}
                helperText={formik.touched.gstNuumber && formik.errors.gstNuumber}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input labelinput={"Pan Number"} type="text"
                name="panNumber"
                {...formik.getFieldProps('panNumber')}
                error={formik.touched.panNumber && Boolean(formik.errors.panNumber)}
                helperText={formik.touched.panNumber && formik.errors.panNumber}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input labelinput={"Firm Name"} type="text"
                name="firmName"
                {...formik.getFieldProps('firmName')}
                error={formik.touched.firmName && Boolean(formik.errors.firmName)}
                helperText={formik.touched.firmName && formik.errors.firmName}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input labelinput={"Email"} type="text"
                name="email"
                {...formik.getFieldProps('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input labelinput={"Authorized Person's Name"} type="text"
                name="authPersonName"
                {...formik.getFieldProps('authPersonName')}
                error={formik.touched.authPersonName && Boolean(formik.errors.authPersonName)}
                helperText={formik.touched.authPersonName && formik.errors.authPersonName}
              />
            </Grid>
            <Grid item xs={12}>
              <Input labelinput={"Firm Address"} type="text"
                name="firmAddress"
                {...formik.getFieldProps('firmAddress')}
                error={formik.touched.firmAddress && Boolean(formik.errors.firmAddress)}
                helperText={formik.touched.firmAddress && formik.errors.firmAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"City"} type="text"
                name="city"
                {...formik.getFieldProps('city')}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"State"} type="text"
                name="state"
                {...formik.getFieldProps('state')}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Zip Code"} type="text"
                name="pincode"
                {...formik.getFieldProps('pincode')}
                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                helperText={formik.touched.pincode && formik.errors.pincode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Person Name"} type="text"
                name="personName"
                {...formik.getFieldProps('personName')}
                error={formik.touched.personName && Boolean(formik.errors.personName)}
                helperText={formik.touched.personName && formik.errors.personName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Contact No."} type="text"
                name="mobile"
                {...formik.getFieldProps('mobile')}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelinput={"Party Reference"} type="text"
                name="partyReference"
                {...formik.getFieldProps('partyReference')}
                error={formik.touched.partyReference && Boolean(formik.errors.partyReference)}
                helperText={formik.touched.partyReference && formik.errors.partyReference}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography>Sales Representative</Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{ marginTop: "-6px" }}
              >
                <Autocomplete
                  options={representativeData}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    representativeData.find((data) => data.id === formik.values.salesRepresentative) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('salesRepresentative', newValue ? newValue.id : '');
                  }}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      error={formik.touched.salesRepresentative && Boolean(formik.errors.salesRepresentative)}
                      helperText={formik.touched.salesRepresentative && formik.errors.salesRepresentative}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography>Category</Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{ marginTop: "-6px" }}
              >
                <Autocomplete
                  options={categoryData}
                  getOptionLabel={(option) => option.categoryName || ""}
                  value={
                    categoryData.find((data) => data.id === formik.values.categoryId) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('categoryId', newValue ? newValue.id : '');
                  }}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                      helperText={formik.touched.categoryId && formik.errors.categoryId}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input labelinput={"Credit Limit"} type="text"
                name="creditLimit"
                {...formik.getFieldProps('creditLimit')}
                error={formik.touched.creditLimit && Boolean(formik.errors.creditLimit)}
                helperText={formik.touched.creditLimit && formik.errors.creditLimit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input labelinput={"Credit Days"} type="text"
                name="creditDays"
                {...formik.getFieldProps('creditDays')}
                error={formik.touched.creditDays && Boolean(formik.errors.creditDays)}
                helperText={formik.touched.creditDays && formik.errors.creditDays}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input
                labelinput="CD % Only For Premium"
                type="number"
                name="cdPer"
                {...formik.getFieldProps('cdPer')}
                error={formik.touched.cdPer && Boolean(formik.errors.cdPer)}
                helperText={formik.touched.cdPer && formik.errors.cdPer}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography>Brand Visibility</Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{ marginTop: "-6px" }}
              >
                <Autocomplete
                  options={[
                    { name: "Yes", id: 1 },
                    { name: "No", id: 0 },
                  ]}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    {
                      name: formik.values.brandVisible === 1 ? "Yes" : formik.values.brandVisible === 0 ? "No" : "",
                      id: formik.values.brandVisible
                    } || null

                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('brandVisible', newValue ? newValue.id : '');
                  }}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      error={formik.touched.brandVisible && Boolean(formik.errors.brandVisible)}
                      helperText={formik.touched.brandVisible && formik.errors.brandVisible}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input labelinput={"Sample Policy"} type="text"
                name="samplePolicy"
                {...formik.getFieldProps('samplePolicy')}
                error={formik.touched.samplePolicy && Boolean(formik.errors.samplePolicy)}
                helperText={formik.touched.samplePolicy && formik.errors.samplePolicy}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input labelinput={"Security Cheque Number"} type="text"
                name="sequrityCheque"
                {...formik.getFieldProps('sequrityCheque')}
                error={formik.touched.sequrityCheque && formik.errors.sequrityCheque}
                helperText={formik.touched.sequrityCheque && formik.errors.sequrityCheque}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input labelinput={"Bank Name"} type="text"
                name="bankName"
                {...formik.getFieldProps('bankName')}
                error={formik.touched.bankName && formik.errors.bankName}
                helperText={formik.touched.bankName && formik.errors.bankName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FileUpload1
                labelinput="Upload Cheque Image"
                files={files} // Array of files
                setFiles={setFiles} // Function to update the files array
                deleteImage={deleteImage}
                setDeleteImage={setDeleteImage}
                setShow1={setShow1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Fileupload3 setShow1={setShow1} setDeleteImage={setDeleteImage} deleteImage={deleteImage} labelinput="GST Certificate" files={files2} setFiles={setFiles2} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Fileupload2 setShow1={setShow1} setDeleteImage={setDeleteImage} deleteImage={deleteImage} labelinput='Pancard Image' files={files1} setFiles={setFiles1} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography fontSize={"16px"} fontWeight={500} mb={"2px"}>
                Notes
              </Typography>
              <EditorProvider>
                <Editor
                  // value={formik.values.note = note}
                  value={formik.values.note}
                  onChange={onChange}
                  placeholder="Enter Notes Here"
                >
                  <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Divider orientation="vertical" flexItem />
                    <BtnBold />
                    <BtnItalic />
                    <BtnBulletList />
                    <BtnNumberedList />
                    <BtnUnderline />
                    <BtnStrikeThrough />
                    {/* <Divider orientation="vertical" flexItem />
                    &nbsp;&nbsp; <BtnStyles /> */}
                  </Toolbar>
                </Editor>
              </EditorProvider>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            {/* <CommonButton buttonName={"Edit"} color="secondary" /> */}
            <Box display={"flex"} gap={1}>
              <CommonButton
                buttonName={"Save"}
                color="white"
                onClick={formik.handleSubmit}
              />
              <CommonButton
                buttonName={"Next"}
                color="primary"
                onClick={onNext}
                disabled={!isNextEnabled}
              />
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Party_wise;
