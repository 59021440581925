import { deleteRequest, get, post, put } from "../web.request";


export const UserDetail = async (id) => {
  return await get(`/adminDetail/${id}`);
}

export const UserList = async (query) => {
  return await get(`/adminList${query}`)
}

export const UserCreate = async (data) => {
  return await post(`/createAdmin `, data);
}

export const UserUpdate = async (id, data) => {
  return await put(`/updateAdmin/${id}`, data);
}

export const UserDelete = async (id) => {
  return await deleteRequest(`/deleteAdmin/${id}`);
}

export const UserStatusChange = async (id, data) => {
  return await put(`/statusChangeAdmin/${id}`, data);
}

export const ChnagePasswordOnMail = async (id) => {
  return await put(`/superAdminChangePassword/${id}`);
}

export const changePassword = async (id, data) => {
  return await put(`/changePassword/${id}`, data);
}

export const salesRepresentativeDropdown = async () => {
  return await get(`/salesRepresentativeDropdown`);
}
