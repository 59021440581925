import React, { useState,useEffect } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import CommonButton from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import FileUpload from "../../../components/common/FileUpload";
import FileUpload1 from "../../../components/common/FileUpload1";
import BackButton from "../../../components/common/Backbutton";
import Heading from "../../../components/Heading";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { addProductValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { addProduct, getProductById, updateProduct } from "../../../Service/product.service";
import { addProductInitialValues } from "../../../helper/initialValues";
import { surfaceDropdown } from "../../../Service/surface.service";
import { sizeDropdown } from "../../../Service/size.service";
import { taxConfigDropdown } from "../../../Service/taxconfig.service";
import { useNavigate } from "react-router-dom";
import { HiOutlineTrash, HiOutlineRefresh } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';

const AddProduct = ({ setShow1 }) => {
  const [files, setFiles] = useState("");
  const [deleteImage, setDeleteImage] = useState([]);
  const [items, setItems] = useState([{ id: 1, key: "", value: "" }]);
  const { state } = useLocation();
  const [sizeData, setSizeData] = useState([]);
  const [surfaceData, setSurfaceData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [deletedSpecificatinIds, setDeletedSpecificatinIds] = useState([])

    const id = state?.id;

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

    const getSizeDropdownData = async () => {
    let res = await sizeDropdown();
    if (res?.status) {
      setSizeData(res.data);
    } else {
      setSizeData([]);
    }
  };

  const getSurfaceDropdownData = async () => {
    let res = await surfaceDropdown();
    if (res?.status) {
      setSurfaceData(res.data);
    } else {
      setSurfaceData([]);
    }
  };

   const getTaxDropdownData = async () => {
    let res = await taxConfigDropdown();
    if (res?.status) {
      setTaxData(res.data);
    } else {
      setTaxData([]);
    }
  };

  useEffect(() => {
    getSizeDropdownData();
    getSurfaceDropdownData();
    getTaxDropdownData();
  }, []);

  const formik = useFormik({
      initialValues: addProductInitialValues,
      onSubmit: async (value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("productName", value.productName);
        formData.append("description", value.description);
        formData.append("price", value.price);
        formData.append("salePrice", value.salePrice);
        formData.append("size", value.size);
        formData.append("surface", value.surface);
        formData.append("tax", value.tax);
        if (files.length > 0) {
          formData.append("productImage", files[0].image);
        }
          formData.append("specification", JSON.stringify(items));

        // items.forEach((item) => {
        //   formData.append("specification", JSON.stringify(item));
        // });

        const res = id ? await updateProduct(id, formData) : await addProduct(formData);
        console.log("res", res);
        if (res.status) {
          setLoading(false);
          dispatch(notificationSuccess({
            show: true,
            type: 'success',
            message: res.message || 'Size Added Successfully.'
          }))
          formik.resetForm();
          navigate("/product");
        
        } else {
          setLoading(false);
          dispatch(notificationSuccess({
            show: true,
            type: 'error',
            message: res.message || 'Something went wrong.'
          }
          ))
        }
      },
      validationSchema: addProductValidationSchema,
  });
  
  const getProductDetail = async () => {
    const res = await getProductById(id);
    if (res.status) {
      formik.setValues({
        productName: res.data.productName,
        description: res.data.description,
        price: res.data.price,
        salePrice: res.data.salePrice,
        size: res.data.size.id,
        surface: res.data.surface.id,
        tax: res.data.tax.id,
      });
      setItems(res.data.specification);
      setFiles([{ image: res.data.productImage, id: res.data.id }]);
    }
  }

  useEffect(() => {
    if (id) {
      getProductDetail();
    }
  }, [])

  const addItem = () => {
    const newItem = { ids: uuidv4(), key: '', value: '' };
    setItems([...items, newItem]);
  };


  const removeItem = (itemId, itema) => {
    let updatedItems = []
    if (itema && itema.id) {
      updatedItems = items.filter((item) => item.id != itema.id);
    } else {
      updatedItems = items.filter((item) => item.ids != itemId);
    }
    setItems(updatedItems);
    if (itema.id) {
      setDeletedSpecificatinIds([...deletedSpecificatinIds, itema.id]);
    }
  };

  const handleChange = (e, itema, field) => {
    let updatedItems = [];
    if (itema.id) {
      updatedItems = items.map(item => {
        if (item.id === itema.id) {
          return { ...item, [field]: e.target.value };
        }
        return item;
      });
    } else {
      updatedItems = items.map(item => {
        if (item.ids == itema.ids) {
          return { ...item, [field]: e.target.value };
        }
        return item;
      });
    }
    setItems(updatedItems);
  };
  const updateTimestamp = (id) => {
    setItems(
      items.map((item) =>
        item.id === id ? { ...item, updatedAt: new Date().toLocaleString() } : item
      )
    );
  };

  console.log("formik", formik);
  return (
    <Stack gap={2}>
      <Box display={"flex"} gap={1} alignItems={"center"}>
        <BackButton />
        <Heading head={id ? "Edit Product" : "Add Product"} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Heading smallHead="Description" />
              <Box className="border_card" p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input labelinput="Product Name" name="productName" maxLength={200}
                       {...formik.getFieldProps('productName')}
                        error={formik.touched.productName && Boolean(formik.errors.productName)}
                        helperText={formik.touched.productName && formik.errors.productName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <Autocomplete
                        options={surfaceData}
                        getOptionLabel={(option) => option.surfaceName || ""} 
                        value={
                          surfaceData.find((role) => role.id === formik.values.surface) || null
                        } // Match the current value with roledata
                        onChange={(event, newValue) => {
                          // Ensure newValue is not undefined or null
                          formik.setFieldValue('surface', newValue ? newValue.id : '');
                        }}
                        renderInput={(params) => (
                          <Input
                            {...params}
                            labelinput="Surface"
                            error={formik.touched.surface && Boolean(formik.errors.surface)}
                            helperText={formik.touched.surface && formik.errors.surface}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <Autocomplete
                        options={sizeData}
                        getOptionLabel={(option) => option.sizeName || ""} 
                        value={
                          sizeData.find((role) => role.id === formik.values.size) || null
                        } // Match the current value with roledata
                        onChange={(event, newValue) => {
                          // Ensure newValue is not undefined or null
                          formik.setFieldValue('size', newValue ? newValue.id : '');
                        }}
                        renderInput={(params) => (
                          <Input
                            {...params}
                            labelinput="Size"
                            error={formik.touched.size && Boolean(formik.errors.size)}
                            helperText={formik.touched.size && formik.errors.size}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Input labelinput="Description" multiline rows={2} name="description" maxLength={500}
                        {...formik.getFieldProps('description')}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Heading smallHead="Pricing" />
              <Box className="border_card" p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Input labelinput="Price" maxLength={200}
                      {...formik.getFieldProps('price')}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}                    
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="standard">
                      <Autocomplete
                        options={taxData}
                        getOptionLabel={(option) => option.taxLabel || ""} 
                        value={
                          taxData.find((role) => role.id === formik.values.tax) || null
                        } // Match the current value with roledata
                        onChange={(event, newValue) => {
                          // Ensure newValue is not undefined or null
                          formik.setFieldValue('tax', newValue ? newValue.id : '');
                        }}
                        renderInput={(params) => (
                          <Input
                            {...params}
                            labelinput="Tax"
                            error={formik.touched.tax && Boolean(formik.errors.tax)}
                            helperText={formik.touched.tax && formik.errors.tax}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Input labelinput="Sale Price" name="salePrice" maxLength={7}
                      {...formik.getFieldProps('salePrice')}
                        error={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                        helperText={formik.touched.salePrice && formik.errors.salePrice}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Heading smallHead="Product Image" />
              <Box className="border_card" p={3}>
                <FileUpload1
                  labelinput="Upload Product Image"
                  files={files} // Array of files
                  setFiles={setFiles} // Function to update the files array
                  deleteImage={deleteImage}
                  setDeleteImage={setDeleteImage}
                  setShow1={setShow1}
                />
              </Box>
            </Grid>

          <Grid item xs={12}>
            <Heading smallHead="Specification" />
                <Box className="border_card" p={3}>
                  <Stack gap={2}>
                    {items?.map((item, i) => {
                        return (
                          <Grid container spacing={{ xs: 1, sm: 2 }} key={item.ids || item.id} justifyContent={'space-between'}>
                            <Grid item xs={3}>
                              <Input placeholder='Key' maxLength={150} value={item.key} onChange={(e) => handleChange(e, item, 'key')} />
                            </Grid>
                            <Grid item xs={7}>
                              <Input placeholder='Value' maxLength={150} value={item.value} onChange={(e) => handleChange(e, item, 'value')} />
                            </Grid>
                            <Grid item xs={2}>
                              {items.length - 1 == i ?
                                <CommonButton buttonName={<MdAdd />} onClick={addItem} />
                                : <CommonButton buttonName={<HiOutlineTrash />} onClick={() => removeItem(item.ids, item)} />
                              }
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Stack>
                  </Box>
                </Grid>
            
          </Grid>
        </Grid>
      </Grid>
      <Stack flexDirection="row" gap={1} mt={2} justifyContent="flex-end">
        <CommonButton buttonName="Cancel" color="white" onClick={() => navigate("/product")} />
        <CommonButton buttonName="Add Product" color="primary"
          onClick={formik.handleSubmit}
        />
      </Stack>
    </Stack>
  );
};

export default AddProduct;
