import axios from "axios";

axios.defaults.withCredentials = false;
const AxiosWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://192.168.29.104:4307/api/v1/",
  crossDomain: false,
});


export default AxiosWithoutToken;



