import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Vector10,
  Dashboard,
  Vector4,
  Vector6,
  Vector1,
  Vector3,
  Vector7,
  Vector5,
  Vector2,
} from "../Constants"; // Import your sub-item icons
import List from "@mui/material/List";
import { Box, ListItemText, Collapse } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess"; // Chevron up icon
import ExpandMore from "@mui/icons-material/ExpandMore"; // Chevron down icon
import logo from '../../assets/images/sidebar/Logo.png'
import { useSelector } from "react-redux";

const SidebarList = [
  {
    name: "Dashboard",
    icon: Vector10,
    activeIcon: Dashboard,
    path: "/",
    show: true
  },
  {
    name: "Dealer Details",
    icon: Vector4,
    activeIcon: Vector4,
    path: "/dealer",
    show: true

  },
  {
    name: "Sample Request",
    icon: Vector6,
    activeIcon: Vector6,
    path: "/sample-request",
    show: true

  },
  {
    name: "Inventory",
    icon: Vector5,
    activeIcon: Vector5,
    path: "",
    show: false,
    subItems: [
      {
        name: "Master Product",
        path: "product",
        icon: Vector3,
        activeIcon: Vector3,
      },
      { name: "Stock", path: "/stock", icon: Vector1, activeIcon: Vector1 },
      {
        name: "Inventory Adjustment",
        path: "/inventory-adjustment",
        icon: Vector7,
        activeIcon: Vector7,
      },
    ],
  },
  {
    name: "Sales Order",
    icon: Vector2,
    activeIcon: Vector2,
    path: "/sales-order",
    show: false,
  },
  {
    name: "Settings",
    icon: Vector4,
    activeIcon: Vector4,
    path: "",
    show: false,
    subItems: [
      { name: "Sizes", path: "/size", icon: Vector7, activeIcon: Vector7 },
      {
        name: "Surfaces",
        path: "/surface",
        icon: Vector1,
        activeIcon: Vector1,
      },
      {
        name: "Categories",
        path: "/category",
        icon: Vector5,
        activeIcon: Vector5,
      },
      {
        name: "Text-config",
        path: "/text-config",
        icon: Vector6,
        activeIcon: Vector6,
      },
      { name: "Roles", path: "/role", icon: Vector10, activeIcon: Vector10 },
      {
        name: "User Manage",
        path: "/user-management",
        icon: Vector4,
        activeIcon: Vector4,
      },
    ],
  },
];

const Sidebar = ({ handleDrawerToggle, drawerWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Separate state for each dropdown
  const [openSettings, setOpenSettings] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);

  const handleToggle = (menu) => {
    if (menu === "Settings") {
      setOpenSettings(!openSettings);
    } else if (menu === "Inventory") {
      setOpenInventory(!openInventory);
    }
  };
  const { user } = useSelector((state) => state?.root?.auth);
  const mailArray = [
    "rahul.pathak@porcious.com",
    "sagar.pachani@porcious.com",
    "dhaval.patel@porcious.com",
    "abhinav.sinha@porcious.com"
  ]

  return (
    <Box width={drawerWidth}>
      <List
      // sx={{
      //   padding: "24px 24px 15px 24px",
      // }}
      >
        <img
          src={logo}
          alt="main_logo"
          style={{
            width: "220px",
          }}
        />
      </List>
      <List>
        {SidebarList.map((data, index) => {
          if (mailArray && mailArray.includes(user?.email) && !data.show) {
            return null;
          } else {


            return <div key={index}>
              <ListItem disablePadding sx={{ marginBottom: "8px" }}>
                <ListItemButton
                  onClick={() => {
                    if (data.subItems) {
                      handleToggle(data.name); // Toggle open/close for items with subItems
                    } else {
                      navigate(data.path);
                    }
                    handleDrawerToggle(); // Optionally close the drawer
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 30,
                      width: "13px",
                      height: "17px",
                    }}
                  >
                    <img
                      src={
                        location.pathname === data.path
                          ? data.activeIcon
                          : data.icon
                      }
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.name}
                    sx={{
                      color:
                        location.pathname === data.path ? "var(--primary)" : "",
                      fontSize: "15px",
                    }}
                  />
                  {data.subItems ? (
                    data.name === "Settings" ? (
                      openSettings ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : data.name === "Inventory" ? (
                      openInventory ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null
                  ) : null}
                </ListItemButton>
              </ListItem>
              {data.subItems && (
                <Collapse
                  in={data.name === "Settings" ? openSettings : openInventory}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {data.subItems.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        sx={{
                          pl: 4,
                          paddingTop: "0px !important",
                          paddingBottom: "0px !important",
                        }} // Indent the sub-items
                        onClick={() => {
                          navigate(subItem.path);
                          handleDrawerToggle(); // Optionally close the drawer
                        }}
                      >
                        <ListItemButton sx={{}}>
                          <ListItemIcon>
                            <img
                              src={
                                location.pathname === subItem.path
                                  ? subItem.activeIcon
                                  : subItem.icon
                              }
                              alt=""
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={subItem.name}
                            sx={{
                              color:
                                location.pathname === subItem.path
                                  ? "var(--primary) !important"
                                  : "",
                              fontSize: "15px",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          }
        })}
      </List>
    </Box>
  );
};

export default Sidebar;
