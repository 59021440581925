import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import CommonButton from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import { CloseIcon } from "../../../helper/Icons";
import { AddTaxConfig, getTaxConfigById, updateTaxConfig } from "../../../Service/taxconfig.service";
import { addTaxConfigInitialValues } from "../../../helper/initialValues";
import { taxConfigValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useEffect } from "react";

const Add_textConfig_modal = ({ handleClose, modalOpen }) => {
    const id = modalOpen.id;

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

    const formik = useFormik({
    initialValues: addTaxConfigInitialValues,
    onSubmit: async (value) => {
      setLoading(true);
      const payload = {
        taxName: value.taxName,
        taxLabel: value.taxLabel,
        taxValue: value.taxValue,
        description: value.description,
      };

      const res = id ? await updateTaxConfig(id, payload) : await AddTaxConfig(payload);
      if (res.status) {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Tax-config Added Successfully.'
        }
        ))
        handleClose();
      } else {
        setLoading(false);
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.'
        }
        ))
      }
    },
    validationSchema: taxConfigValidationSchema,
    });
  
  const getSizeDetail = async () => {
    const res = await getTaxConfigById(id);
    if (res.status) {
      formik.setFieldValue("taxName", res.data.taxName);
      formik.setFieldValue("taxLabel", res.data.taxLabel);
      formik.setFieldValue("taxValue", res.data.taxValue);
      formik.setFieldValue("description", res.data.description);
    }
  }

  useEffect(() => {
    if (id) {
      getSizeDetail();
    }
  }, [])
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        <Typography variant="h6">{id ? "Update" : "Add"} Tax Config</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} width={{ sm: "500px" }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <Input labelinput="Name"
              name="taxName"
              maxLength={100}
              {...formik.getFieldProps('taxName')}
              error={formik.touched.taxName && formik.errors.taxName}
              helperText={formik.touched.taxName && formik.errors.taxName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input labelinput={"Label"} name="label" maxLength={100}
              {...formik.getFieldProps('taxLabel')}
              error={formik.touched.taxLabel && formik.errors.taxLabel}
              helperText={formik.touched.taxLabel && formik.errors.taxLabel}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"TaxValue"} name="taxValue" maxLength={10}
              {...formik.getFieldProps('taxValue')}
              error={formik.touched.taxValue && formik.errors.taxValue}
              helperText={formik.touched.taxValue && formik.errors.taxValue}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Description"}
              name="description"
              maxLength={150}
              {...formik.getFieldProps('description')}
              error={formik.touched.description && formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <CommonButton buttonName={"Save"} size="small"
          onClick = {formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default Add_textConfig_modal;
