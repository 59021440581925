import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Checkbox,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Heading from "../../../components/Heading";
import BackButton from "../../../components/common/Backbutton";
import Input from "../../../components/common/Input";
import CommonButton from "../../../components/common/Button";
import { useFormik } from "formik";
import { roleAddInitialValues } from "../../../helper/initialValues";
import { roleAddValidationSchema } from "../../../helper/validation";
import { RoleCreate, RoleDetail, RoleUpdate,PageList } from "../../../Service/Role.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";

const AddRole = () => {
  const [pageListData, setPageListData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckboxChange = (moduleIndex, permission) => {
    setPermissions(prevPermissions => {
      const newPermissions = [...prevPermissions];
      newPermissions[moduleIndex] = {
        ...newPermissions[moduleIndex],
        [permission]: !newPermissions[moduleIndex][permission],
      };
      return newPermissions;
    });
  };

  const getPageList = async () => {
    const res = await PageList();
    if (res?.status) {
      setPageListData(res.data);
      setPermissions(res.data.map((item) => ({
        readP: false,
        writeP: false,
        deleteP: false,
        pageId: item.id,
        pageName: item.pageName,
      })));
    } else {
      setPageListData([]);
    }
  }

  // useEffect(() => {
  //   getPageList();
  // }, []);


  const formik = useFormik({
    initialValues: roleAddInitialValues,
    onSubmit: async (value) => {

      const payload = {
        roleName: value.roleName,
        permissions: permissions,
      }

      console.log('payload', payload);

      let res = isEdit ? await RoleUpdate(editId, payload) : await RoleCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }))
        formik.resetForm();
        navigate('/role', {
          state: { isRole: true }
        });

      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }
        ))
      }

    },
    validationSchema: roleAddValidationSchema,
  });

  useEffect(() => {
    if (state && state?.isEdit && state?.id) {
      setIsEdit(true);
      setEditId(state.id);
      getroleDetails(state.id);
    } else {
      setEditId(null);
      setIsEdit(false);
      getPageList();
    }

  }, [state]);

  const getroleDetails = async (id) => {
    let res = await RoleDetail(id);
    if (res?.status) {
      formik.setValues({
        roleName: res.data.roleName,
      });
      if (res && res?.data && res?.data?.permission) {
        setPermissions(res.data.permission.map((item) => ({
          readP: item.readP == 1 ? true : false,
          writeP: item.writeP == 1 ? true : false,
          deleteP: item.deleteP == 1 ? true : false,
          pageId: item.pageId,
          id: item.id,
          pageName: item.pageName,
        })));
      }
    }
  }



  return (
    <Stack gap={2}>
      <Box
      display={"flex"}
        gap={1}
        alignItems={"center"}
      >
        <BackButton />
        <Heading head={`Create Role`} />
      </Box>
      <Box className="card_container">
        <Grid container spacing={2} maxWidth={{ sm: "900px" }}>
          <Grid item xs={12}>
            <Input labelinput={"RoleName"} name="roleName" maxLength={50} variant="outlined"
              {...formik.getFieldProps('roleName')}
              error={formik.touched.roleName && formik.errors.roleName}
              helperText={formik.touched.roleName && formik.errors.roleName}

            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "13px", sm: "16px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Role Management"}
            </Typography>
            <Box>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Module Name</TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {"Read"}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {"Write"}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {"Delete"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissions.map((module, moduleIndex) => (
                      <TableRow key={moduleIndex} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                        <TableCell component="th" scope="row">{module.pageName}</TableCell>

                        <TableCell align='center'>
                          <Checkbox
                            checked={permissions[moduleIndex].readP}
                            onChange={() => handleCheckboxChange(moduleIndex, 'readP')}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <Checkbox
                            checked={permissions[moduleIndex].writeP}
                            onChange={() => handleCheckboxChange(moduleIndex, 'writeP')}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <Checkbox
                            checked={permissions[moduleIndex].deleteP}
                            onChange={() => handleCheckboxChange(moduleIndex, 'deleteP')}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
        <Stack flexDirection={"row"} mt={2} gap={1}>
          <CommonButton
            buttonName={"Cancel"}
            size="small"
            color="white"
            onClick={() => navigate("/role")}
          />
          <CommonButton
            buttonName={"Create"}
            size="small"
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default AddRole;
