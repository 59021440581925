import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import Heading from "../../components/Heading";
import { FaEdit, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/common/Button";
import { useStyles } from "../../helper/Theme";
import CommonModal from "../../components/common/Modal";
import { Download, Edit } from "@mui/icons-material";
import { MdDownload } from "react-icons/md";
import TableSearch from "../../components/common/Table/TableSearch";
import { SampleRequestList } from "../../Service/sample.service";
import Pagination from "../../components/common/Table/Pagination";
import { useEffect } from "react";

const Selection_Confi = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [sampleRequestData, setSampleRequestData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getSampleRequestList = async () => {
    setSampleRequestData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await SampleRequestList(query);
    if (res?.status) {
      setSampleRequestData(res?.data);
      setTotalData(res?.total);
    } else {
      setSampleRequestData(null);
    }
  }

  useEffect(() => {
    getSampleRequestList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };
  return (
    <>
      <Stack gap={2}>
        <Box>
          <Heading head={"Sample Request"} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <CommonButton
              buttonName="Create New"
              onClick={() => navigate("/sample-form", { isEdit: false, currentTab: 0 })}
            />
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>SR</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Desplay Size</TableCell>
              <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
            </TableHead>
            <TableBody>
              {sampleRequestData?.map((row, index) => (
                <TableRow sx={{ cursor: 'pointer' }} >
                  <TableCell onClick={() => navigate("/view-sample-requester", { state: { id: row?.id } })}>
                    {index + 1}
                  </TableCell>
                  <TableCell onClick={() => navigate("/view-sample-requester", { state: { id: row?.id } })}>
                    {row.dealerName || "-"}
                  </TableCell>
                  <TableCell onClick={() => navigate("/view-sample-requester", { state: { id: row?.id } })}>
                    {row.demail || "-"}
                  </TableCell>
                  <TableCell onClick={() => navigate("/view-sample-requester", { state: { id: row?.id } })}>
                    {row.desplaySize || "-"}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip title="View Details">
                        <IconButton
                          onClick={() => navigate("/view-sample-requester", { state: { id: row?.id } })}
                          size="small"
                        >
                          <FaEye />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Details">
                        <IconButton
                          onClick={() => navigate("/sample-form-edit", { state: { id: row?.id, isEdit: true, currentTab: 0 } })}
                          size="small"
                        >
                          <FaEdit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download PDF">
                        <IconButton size="small">
                          <MdDownload />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {sampleRequestData !== null && (

        <Pagination totalRecords={totalData}
          data={sampleRequestData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />)}
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
          getSampleRequestList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Selection_Confi;
