import { get, post,put,deleteRequest, } from "../web.request";

export const addProduct = async (data) => {
  return await post(`/addProduct`, data);
}

export const updateProduct = async (id,data) => {
  return await put(`/updateProduct/${id}`, data);
}

export const productList = async (query) => {
  return await get(`/productList${query}`);
}

export const productDropdown = async () => {
  return await get(`/productDropdown`);
}

export const deleteProduct = async (id) => {
  return await deleteRequest(`/deleteProduct/${id}`);
}

export const getProductById = async (id) => {
  return await get(`/productDetail/${id}`);
}

export const statusChangeProduct = async (id) => {
  return await put(`/statusChangeProduct/${id}`);
}


