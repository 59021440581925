import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import BackButton from "../../components/common/Backbutton";
import Heading from "../../components/Heading";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { SampleRequestDetail } from "../../Service/sample.service";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

const View_Sample_Requester = () => {
  const navigate = useNavigate();
  const [sampleRequest, setSampleRequestData] = React.useState({});
  const { state } = useLocation();
  useEffect(() => {
    if (state && state?.id) {
      getSampleRequest(state.id);
    }
  }, [state]);

  const getSampleRequest = async (id) => {
    let res = await SampleRequestDetail(id);
    if (res?.status) {
      setSampleRequestData(res.data);
    } else {
      setSampleRequestData({});
    }
  }
  return (
    <div>
      <Stack gap={2}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
          >
            <BackButton />
            <Heading head={`${sampleRequest?.dealerId?.authPersonName}`} />
          </Box>
          <Box>
            <CommonButton buttonName="Edit" color="secondary" onClick={() => navigate("/sample-form-edit", { state: { id: state?.id, isEdit: true } })} />
          </Box>
        </Box>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Name :</Typography>
                <Typography>{sampleRequest?.dealerId?.authPersonName}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>City :</Typography>
                <Typography>{sampleRequest?.dealerId?.city}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>State :</Typography>
                <Typography>{sampleRequest?.dealerId?.state}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Zip Code :</Typography>
                <Typography>{sampleRequest?.dealerId?.pincode}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Date :</Typography>
                <Typography>{moment(sampleRequest?.dealerId?.date).format("YYYY-MM-DD")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>GST Number :</Typography>
                <Typography>{sampleRequest?.dealerId?.gstNuumber}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Category :</Typography>
                <Typography>{sampleRequest?.categoryId?.categoryName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Rough Pads :</Typography>
                <Typography>{sampleRequest?.roughPads}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Pen Packets :</Typography>
                <Typography>{sampleRequest?.penPocket}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Loading DATE :</Typography>
                <Typography>{moment(sampleRequest?.loadingDate).format('YYYY/MM/DD')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Processing Start DATE :</Typography>
                <Typography>{moment(sampleRequest?.processStartDate).format('YYYY/MM/DD')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="card-details">
                <Typography>Material Ready DATE:</Typography>
                <Typography>{moment(sampleRequest?.materialReadyDate).format("YYYY/MM/DD")}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>Surface</TableCell>
                <TableCell>Design Name</TableCell>
                <TableCell>Sample</TableCell>
                <TableCell>Preview</TableCell>
                <TableCell>QR</TableCell>
                <TableCell>Small PCS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sampleRequest?.sampleProducts?.map((item, index) => (
                // item.surfaces.map((surface, subIndex) => (
                <TableRow
                  // key={`${index}-${subIndex}`}
                  sx={{
                    "&:hover": {
                      boxShadow: "none !important",
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  {/* {subIndex === 0 && (
                      <TableCell rowSpan={item.surfaces.length}>
                        {surface.Surface}
                      </TableCell>
                    )} */}
                  <TableCell>{item?.size?.sizeName}</TableCell>
                  <TableCell>{item?.surface?.surfaceName}</TableCell>
                  <TableCell>{item?.design?.productName}</TableCell>
                  <TableCell>{item?.sample}</TableCell>
                  <TableCell>{item?.preview === 1 ? "Yes" : "No"}</TableCell>
                  <TableCell>{item?.qr === 1 ? "Yes" : "No"}</TableCell>
                  <TableCell>{item?.smallPCS === 1 ? "Yes" : "No"}</TableCell>
                </TableRow>
                // )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography fontWeight={800}>Notes :</Typography>
                <Box>
                  <Typography>
                    {sampleRequest?.notes}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </div>
  );
};

export default View_Sample_Requester;
