import React, { useState,useEffect } from "react";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  IconButton,
  Tooltip,
} from "@mui/material";
import Heading from "../../../components/Heading";
import CommonButton from "../../../components/common/Button";
import img from "../../../assets/images/tiles.jfif";
import CommonModal from "../../../components/common/Modal";
import TableSearch from "../../../components/common/Table/TableSearch";
import { FaEdit, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { deleteProduct, productList, statusChangeProduct } from "../../../Service/product.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import Pagination from "../../../components/common/Table/Pagination";

const Product = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const handleClick = (event, image) => {
    setAnchorEl(event.currentTarget);
    setCurrentImage(image);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentImage("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getProductList = async () => {
    setProductData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await productList(query);
    if (res?.status) {
      setProductData(res?.data);
      setTotalData(res?.total);
    } else {
      setProductData(null);
    }
  }
  
  useEffect(() => {
    getProductList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

 
  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == 'status' ? await statusChangeProduct(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await deleteProduct(isModalOpen?.id) : null;
      console.log("res",res);

    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getProductList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || 'Something went wrong. Please try again later.'
      }))
    }
  }

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Product"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
          <CommonButton
            buttonName="Add Product"
            onClick={() => navigate("/add-product", { isEdit: false })}
          />
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SR</TableCell>
                  {/* <TableCell>Image</TableCell> */}
                  <TableCell> Name</TableCell>
                  <TableCell>Surface</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Price</TableCell>

                  <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productData && productData.map((row,index) => (
                  <TableRow key={index}  sx={{cursor:"pointer"}}>
                    <TableCell
                      onClick={() => {
                        navigate("/view-product", { state: { id: row.id } });
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    {/* <TableCell sx={{ width: "fit-content !important",cursor:"pointer" }}>
                      
                    </TableCell> */}
                    <TableCell>
                      <Box display={"flex"} gap={1}>
                        <Avatar
                          sx={{ width: 30, height: 30 }}
                          src={row.productImage}
                          onClick={(e) => handleClick(e, row.productImage)} // Open popover on click
                        />
                        {row.productName}
                      </Box>
                    </TableCell>
                    <TableCell
                     onClick={() => navigate("/view-product", { state: { id: row.id } })}
                    >
                      {row.surfaceName}
                    </TableCell>
                    <TableCell
                     onClick={() => navigate("/view-product", { state: { id: row.id } })}
                    >
                      {row.sizeName}
                    </TableCell>
                    <TableCell
                     onClick={() => navigate("/view-product", { state: { id: row.id } })}
                    >
                      {row.price}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="View Details">
                          <IconButton
                           onClick={() => navigate("/view-product", { state: { id: row.id } })}
                            size="small"
                          >
                            <FaEye />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Details">
                          <IconButton
                            onClick={() => navigate("/add-product", {state:{id:row.id}, isEdit: true })}
                            size="small"
                          >
                            <FaEdit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton size="small"
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "delete",
                                id: row.id,
                                head: "Product Delete", para: "Are you sure you want to Delete the Product?" 

                                });
                            }}
                          >
                            <MdDelete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Popover for showing the full image */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            component="img"
            src={currentImage}
            alt="Design Image"
            sx={{
              width: 300, // Adjust the width as needed
              height: "auto",
              padding: 1,
            }}
          />
        </Popover>
      </Stack>
      {productData !== null && (

        <Pagination totalRecords={totalData}
          data = {productData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />)}
      <CommonModal
        modalOpen={isModalOpen}
         handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "",id: "" });
          getProductList(); // Call the function after the modal is closed or an action is performed
        }}
      />
    </>
  );
};

export default Product;
