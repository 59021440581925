import {
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useStyles } from "../../helper/Theme";
import {
  DataObjectOutlined,
  LocationCityOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

const CounterCard = () => {
  const classes = useStyles();

  const CounterData = [
    {
      para: "Location",
      number: 18,
      icon: <LocationCityOutlined />,
    },
    {
      para: "Users",
      number: 23,
      icon: <Person2Outlined />,
    },  
    {
      para: "Product",
      number: 156,
      icon: <CategoryOutlinedIcon   />,
    },
  ];

  return (
    <Stack
      flexDirection={{ xs: "column", md: "row" }}
      gap={2}
      sx={{ width: "100%" }}
    >
      <Grid container spacing={2}>
        {CounterData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{
                width: "100%",
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "6px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                ":hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack>
                  <Typography
                    color={"#888"}
                    textTransform={"capitalize"}
                    fontWeight="500"
                    fontSize="14px"
                  >
                    {item.para}
                  </Typography>
                  <Typography variant="h5" fontWeight="bold">
                    {item.number}
                  </Typography>
                </Stack>
                <Box>
                  <Avatar sx={{ bgcolor: "#E54E09" }}>{item.icon}</Avatar>
                </Box>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default CounterCard;