import React, { useState, useCallback } from 'react';
import { Button, Box, Grid, Paper, IconButton, Typography, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function Fileupload2({ labelinput, setShow1, files, setFiles, setDeleteImage, deleteImage }) {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event) => {
    //size 5 mb up then not upload
    if (event.target.files && event.target.files[0] && event.target.files[0].size > 5000000) {
      setShow1({
        show: true,
        type: 'error',
        message: "File is too big!" || 'Something went wrong',
      });

      return;
    }

    setFiles([...files, { image: event.target.files[0] }]);
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback((event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size > 5000000) {
      setShow1({
        show: true,
        type: 'error',
        message: "File is too big!" || 'Something went wrong',
      });
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length) {
      setFiles([...files, { image: droppedFiles[0] }]);
    }
  }, [files]);

  const handleRemoveFile = (index, file) => {
    console.log("filefilefilefile", deleteImage);

    if (file?.id) {
      setDeleteImage([...deleteImage, file.id])
    }
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleUploadClick = () => {
    // Upload logic goes here
  };

  return (
    <Box>
      {labelinput && <Typography fontSize={'16px'} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
      <label htmlFor="raised-button-file" onClick={handleUploadClick}>
        <Box
          sx={{
            width: '100%',
            height: '70px',
            border: '1px dashed #c4c4c4',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: dragging ? '#e0e0e0' : '#f8f8f8',
            fontSize: '14px',
            fontWeight: '600',
            cursor: 'pointer'
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          Drop your files here or click to browse
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
        </Box>
      </label>

      {/* Thumbnails */}
      <Stack gap={'10px'} flexDirection={'row'} flexWrap={'wrap'} style={{ marginTop: '10px' }}>
        {files?.map((file, index) => (
          file?.image != undefined &&
          <Box key={index} sx={{ width: '70px', height: '70px', position: 'relative', border: '1px solid #e3e3e3', borderRadius: '10px' }}>
            <IconButton
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => handleRemoveFile(index, file)}
            >
              <DeleteIcon sx={{ fontSize: '16px' }} />
            </IconButton>

            {file && file?.id ? (<img
              src={file?.image}
              alt={`preview ${index}`}
              style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
            />) : (
              file?.image != undefined && (<img
                src={URL.createObjectURL(file?.image)}
                alt={`preview ${index}`}
                style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
              />)
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default Fileupload2;