import { get, post,put,deleteRequest, } from "../web.request";

export const AddSize = async (data) => {
  return await post(`/addSize`, data);
}

export const updateSize = async (id,data) => {
  return await put(`/updateSize/${id}`, data);
}

export const sizeList = async (query) => {
  return await get(`/sizeList${query}`);
}

export const sizeDropdown = async () => {
  return await get(`/sizeDropdown`);
}

export const deleteSize = async (id) => {
  return await deleteRequest(`/deleteSize/${id}`);
}

export const getSizeById = async (id) => {
  return await get(`/sizeDetail/${id}`);
}

export const statusChangeSize = async (id) => {
  return await put(`/statusChangeSize/${id}`);
}


