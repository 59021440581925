import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { makeStyles } from '@mui/styles';
// import { BgImage } from './Constant';

export const useStyles = makeStyles(({ theme }) => ({
    tableContainer: {
        boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15) !important',
        borderRadius: '15px !important',
        border: '2px solid #FFF !important',
        background: 'var(--cardBG)'
    },
    cardContainer: {
        boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15) !important',
        borderRadius: '15px !important',
        border: '2px solid #FFF !important',
        // padding:'30px',
        minWidth: '100%',
        height: '100%',
        // marginBottom: '20px'
        background: 'var(--cardBG)',
        position: 'relative',
    },
    //   mainContainer: {
    //     backgroundImage: `url(${BgImage})`,
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     minHeight: '100vh',
    //   }
    chartContainer: {
        boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15) !important',
        borderRadius: '15px !important',
        border: '2px solid #FFF !important',
        minWidth: '100%',
        height: '100%',
        position: 'relative',
        background: 'var(--cardBG)'
    },
    smallHead: {
        color: '#797D8C',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '15px',
        paddingBottom: '8px'
    },
    goalHead: {
        color: '#000',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '14px'
    },
    counterNumber: {
        color: '#000',
        fontSize: '28px',
        lineHeight: '28px',
        fontWeight: '700',
    },
    counterImg: {
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: '15px',
        height: '15px'
    },
    percentage: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        color: ' #6AD2A0',
        fontSize: '12px',
        fontWeight: '400',
    },
    chartHead: {
        fontSize: '20px',
        fontWeight: '600',
    },
    settingBtn: {
        fontSize: '20px',
        fontWeight: '600',
    },
    settingIcon: {
        minWidth: '30px !important',
        height: '30px !important',
        color: 'var(--primary)',
        padding: '3px',
        background: '#dfebeb',
        borderRadius: '50%',
        // marginTop: '5px !important'
    },
    select: {
        right: '30px !important',
    }
}));



export const theme = createTheme({
    palette: {
        primary: {
            main: '#E54E09',
            // main: '#103c54',

        },
        secondary: {
            main: '#00074E',
        },
        black: {
            main: '#000000',
        },
        white: {
            main: '#FFFFFF',
        },
        gray: {
            main: '#4A4A4A',
            secondary: '#2D2D2D',
            head: '#010425',
            para: '#292929',
            cardtext: '#858585',
        },
        border: {
            main: '#D4C3C4',
        },
        red: {
            main: '#FF5636'
        },
        green: {
            main: '#00AD6F'
        },
        lightprimary: {
            main: '#00BFF570',
        },
    },
    components: {
        // button -----------
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    textTransform: 'capitalize !important',
                    fontSize: '18px !important',
                    lineHeight: '1 !important',
                    fontWeight: '500',
                    color: '#fff',
                    padding: '11px 13px',
                    width: 'fit-content',
                    minWidth: 'unset',
                    whiteSpace: 'nowrap',
                    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
                    '@media (max-width: 600px)': {
                        fontSize: '14px !important',
                        padding: '10px 12px',
                    },
                    '& .MuiButton-startIcon svg': {
                        '@media (max-width: 600px)': {
                            fontSize: '19px !important',
                        },
                    }
                },
                containedWhite: {
                    color: '#00074E',
                },
                sizeSmall: {
                    padding: '12px 15px',
                    fontSize: '16px !important',
                },
                containedGreen: {
                    padding: '10px 20px',
                }
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '7px',
                }
            }
        },
        // button -----------
        // input ------------
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        input: {
                            fontSize: '18px',
                            fontWeight: '400',
                            borderRadius: '5px',
                            padding: '6px 12px',
                            color: '#000',
                            background: 'transparent',
                            // boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
                            border: '1px solid #292929',
                            '@media (max-width: 600px)': {
                                fontSize: '14px !important',
                                padding: '6px 10px',
                            },
                        },
                        fieldset: {
                            // border: 'unset !important',
                        }
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                        color: '#747474',
                    },
                    '& .MuiInputBase-adornedStart ': {
                        paddingLeft: '10px',
                        input: {
                            padding: '9px 14px 9px 0',
                        },
                    }
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    padding: '5px',
                    border:'none',
                },
                root: {
                    '& .MuiInputBase-input': {
                        // boxShadow: 'unset !important',
                        // border: 'unset !important',
                    }
                },
                input: {
                }
            }
        },
        // input ------------
        // select ------------
        MuiSelect: {
            styleOverrides: {
              root: {
                borderRadius: "6px",
              },
              select: {
                fontSize: "13px",
                fontWeight: "400",
                
                padding: "8px 15px",
                minHeight: "unset",
                color: "#000",
                //   border: '1px solid #292929',
                "@media (max-width: 600px)": {
                  fontSize: "14px !important",
                  padding: "10px 15px",
                },
                fieldset: {
                    border: '1px solid #292929',
                },
                "& .MuiInputBase-root:hover": {},
                "& .MuiInputBase-input::placeholder": {
                  color: "#000",
                },
              },
            },
          },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '18px',
                    '@media (max-width: 600px)': {
                        fontSize: '14px !important',
                        minHeight: '35px !important',
                    },
                }
            }
        },
        // select ------------
        // table -----------
        MuiTableContainer:{
            styleOverrides: {
                root: {
                    boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15) !important',
                    borderRadius: '10px !important',
                    border: '2px solid #FFF !important',
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    '& .MuiTableHead-root': {
                        '& .MuiTableCell-root': {
                            fontWeight: '700',
                            textTransform: 'capitalize',
                            color: '#000'
                        }
                    },
                    '& .MuiTableCell-root': {
                        padding: '12px 15px',
                        fontSize: '16px',
                        whiteSpace: 'nowrap',
                        // color: '#4E4E4E'
                    },
                    // Media query for smaller screens
                    '@media (max-width: 600px)': {
                        '& .MuiTableCell-root': {
                            fontSize: '13px',
                            padding: '10px 10px',

                        },
                    },
                    '& .MuiTableBody-root': {
                        '& .MuiTableRow-root:hover': {
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                            background: '#E54E0910'
                        },
                    },
                    '& .MuiReportTable-root': {
                        '& .MuiTableCell-root': {
                            textAlign: 'center !important',
                            // '&:nth-of-type(2)': {
                            //   textAlign: 'start !important',
                            // },
                            '&:first-of-type': {
                                textAlign: 'start !important',
                            },
                            '&:last-child': {
                                textAlign: 'end !important',
                            },
                        }
                    },
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '15px !important',
                    height: '28px',
                    fontSize: '16px',
                    '@media (max-width: 600px)': {
                        height: '24px',
                        fontSize: '14px',
                    },
                    '& .MuiSvgIcon-root ': {
                        fontSize: '18px',
                    }
                }
            }
        },
        // table -----------
        // modal -----------
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    fontWeight: '600',
                }
            }
        },
        // modal -----------
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiButtonBase-root': {
                        minHeight: '40px',
                        textTransform: 'capitalize',
                        alignItems: 'flex-start'
                    },
                    '& .Mui-selected ': {
                        background: '#eef4f6',
                    },
                    '& .MuiTabs-indicator': {
                        left: 0,
                        right: 'unset',
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    // zIndex: '9999',
                },
                paper: {
                    '@media (max-width: 600px)': {
                        margin: '16px',
                        width: '100%'
                    },
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px 24px',
                    '@media (max-width: 600px)': {
                        padding: '12px 16px',
                    },
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '16px'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h6: {
                    fontSize: '20px !important'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    marginBottom: 0,
                }
            }
        },
    },
});

export const MyComponent = styled('div')({
    borderRadius: '0px',
    background: '#151515',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
});

export const getEducatorsStatus = (value) => {
    const status = {
        false: {
            title: "Absent",
            styles: {
                backgroundColor: "#FF563625",
                color: "#FF5636",
            },
        },
        true: {
            title: "Present ",
            styles: {
                backgroundColor: "rgba(18, 183, 106, 0.20)",
                color: "#00AD6F",
            },
        },

    };
    return status[value];
};

export const getAlertStatus = (value) => {
    const status = {
        false: {
            title: "Off",
            styles: {
                backgroundColor: "#FF563625",
                color: "#FF5636",
            },
        },
        true: {
            title: "On",
            styles: {
                backgroundColor: "rgba(18, 183, 106, 0.20)",
                color: "#00AD6F",
            },
        },

    };
    return status[value];
};