import React, { useState, useEffect } from "react";
import Party_form from "./Party_form";
import Party_table from "./Party_table";
import { useFormik } from "formik";
import { DealerCreate, DealerDetail, DealerUpdate } from "../../Service/dealer.service";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { addDealerInitialValues } from "../../helper/initialValues";
import { addDealerValidationSchema, dealerProductValidationShema, dealerValidationSchema } from "../../helper/validation";
import moment from "moment";
import { DealerVersionDropdown } from "../../Service/dealer.service";

const Party_Form = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);
  const [version, setVersion] = useState("");
  const [versionData, setVersionData] = useState([]);
  const [rows, setRows] = useState([]);

  console.log("deleteImagedeleteImage", deleteImage);

  const handleNext = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };
  const { state } = useLocation();

  const handlePrevious = () => {
    setCurrentTab((prevTab) => prevTab - 1);
    if (state?.isEdit) {
      navigate('/dealer-form-edit', { state: { isEdit: true, id: editId, currentTab: 0 } });
    } else {
      navigate('/dealer-form', { state: { isEdit: false, currentTab: 0 } });
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state && state?.id) {
      setIsEdit(true);
      getVersionData(state?.id);
      if (version != "") {
        getEmployeeDetails(state.id, version);
      }
      setEditId(state.id);
    } else {
      setEditId(null);
      setIsEdit(false);
    }
  }, [state, version]);

  useEffect(() => {
    if (state && state?.id) {
      getVersionData(state?.id);
    }
  }, [state]);

  const getVersionData = async (id) => {
    let res = await DealerVersionDropdown(id);
    if (res?.status) {
      setVersionData(res.data);
      if (res.data[0]?.index && version == "") {
        setVersion(res.data[0]?.index);
      }
    }
  };

  const getEmployeeDetails = async (id, version) => {
    let res = await DealerDetail(id, version);
    if (res?.status) {
      formik.setValues({
        ...formik.values,
        note: res.data.dealerNotes?.note || "",
        zone: res.data.zone || "",
        srNo: res.data.srNo || "",
        creditDays: res.data.creditDays || "",
        date: moment(res.data.date).format("YYYY-MM-DD") || "",
        gstNuumber: res.data.gstNuumber || "",
        panNumber: res.data.panNumber || "",
        authPersonName: res.data.authPersonName || "",
        firmName: res.data.firmName || "",
        email: res.data.email || "",
        firmAddress: res.data.firmAddress || "",
        city: res.data.city || "",
        state: res.data.state || "",
        pincode: res.data.pincode || "",
        personName: res.data.personName || "",
        mobile: res.data.mobile || "",
        partyReference: res.data.partyReference || "",
        salesRepresentative: res.data.salesRepresentative?.id || "",
        categoryId: res.data.categoryId?.id || "",
        creditLimit: res.data.creditLimit || "",
        cdPer: res.data.cdPer || "",
        samplePolicy: res.data.samplePolicy || "",
        sequrityCheque: res.data.sequrityCheque || "",
        bankName: res.data.bankName || "",
        brandVisible: res.data.brandVisible || "",
        dealerProducts: res.data.dealerProducts || [],
      });
      setFiles([{ image: res.data.chequeImage, id: res.data.id }]);
      setRows(res.data.dealerProducts.map((item) => ({
        size: item.size?.id ? item.size.id : 0,
        surface: item.surface ? item.surface?.id : 0,
        rate: item.rate ? item.rate : 0,
        remark: item.remark ? item.remark : "",
        id: item?.id || 0,
      })));
      if (res?.data?.panImages?.length > 0) {
        let data = res?.data?.panImages.map((item) => {
          console.log("itemitemitemitemlkjhfxz", item);
          return { image: item.image, id: item.id };
        }
        );
        setFiles1(data);
        console.log("datadshdkjshdkjshdks", data);
      }
      if (res?.data?.gstImages?.length > 0) {
        let data = res?.data?.gstImages?.map((item) => {
          console.log("itemitemitemitemlkjhfxz", item);
          return { image: item.image, id: item.id };
        }
        );
        setFiles2(data);
        console.log("testtttttttt", data);
      }
    }
  };

  const checkvalidation = () => {
    let error = false;
    rows.map((item) => {
      if (item.size == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Size is required'
        }));
        error = true;
      }
      if (item.surface == "") {
        dispatch(notificationSuccess({

          show: true,
          type: 'error',
          message: 'Surface is required'
        }));
        error = true;
      }
      if (item.rate == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Rate is required'
        }));
        error = true;
      }

    });
    return error;
  };

  const formik = useFormik({
    initialValues: addDealerInitialValues,
    onSubmit: async (values) => {


      if (currentTab === 1) {
        //check validation for dealer products
        const res = checkvalidation()
        if (res) {
          return;
        }
      }

      console.log("filesfilesfilesfiles", files);

      if (!files) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Cheque Image is required'
        }));
        return;
      }

      const formData = new FormData();
      formData.append("zone", values.zone);
      formData.append("srNo", values.srNo);
      formData.append("date", values.date || "");
      formData.append("gstNuumber", values.gstNuumber);
      formData.append("panNumber", values.panNumber);
      formData.append("authPersonName", values.authPersonName);
      formData.append("firmName", values.firmName);
      formData.append("email", values.email);
      formData.append("firmAddress", values.firmAddress);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("pincode", values.pincode);
      formData.append("personName", values.personName);
      formData.append("mobile", values.mobile);
      formData.append("partyReference", values.partyReference);
      formData.append("salesRepresentative", values.salesRepresentative);
      formData.append("categoryId", values.categoryId);
      formData.append("creditLimit", values.creditLimit);
      formData.append("creditDays", values.creditDays == "" ? 0 : values.creditDays);
      formData.append("cdPer", values.cdPer);
      formData.append("samplePolicy", values.samplePolicy);
      formData.append("sequrityCheque", values.sequrityCheque);
      formData.append("bankName", values.bankName);
      formData.append("brandVisible", values.brandVisible);
      formData.append("note", values.note);
      if (files.length > 0) {
        formData.append("chequeImage", files[0].image);
      }
      formData.append("dealerProducts", JSON.stringify(rows));
      if (files1.length > 0) {
        files1.map((item) => {
          formData.append("panImage", item.image);
        });
      }
      if (files2.length > 0) {
        files2.map((item) => {
          formData.append("gstImage", item.image);
        });
      }

      //deleteImage
      formData.append("deleteImage", deleteImage);


      // Determine if it's an update or create operation
      let res;
      if (editId) {
        res = await DealerUpdate(editId, formData);
      } else {
        res = await DealerCreate(formData);
      }


      // Handle the response
      if (res?.status) {
        if (currentTab != 1) {
          handleNext();
          navigate('/dealer-form-edit', { state: { isEdit: true, id: res.data.id, currentTab: 1 } });
          return;
        }
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }));
        navigate('/dealer');
        formik.resetForm();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }));
      }

    },
    validationSchema: currentTab === 0 ? dealerValidationSchema : currentTab === 1 ? "" : addDealerValidationSchema,
  });


  useEffect(() => {
    if (state?.editId) {
      setIsEdit(true);
      if (version != "") {
        getEmployeeDetails(state.editId, version);
      }
    }
  }, [state, version]);


  return (
    <div>
      {currentTab === 0 && <Party_form onNext={handleNext} setDeleteImage={setDeleteImage} deleteImage={deleteImage} formik={formik} isEdit={isEdit} files2={files2} setFiles2={setFiles2} files1={files1} setFiles1={setFiles1} files={files} setFiles={setFiles} versionData={versionData} version={version} setVersion={setVersion} />}
      {currentTab === 1 && (
        <Party_table
          rows={rows}
          setRows={setRows}
          onPrevious={handlePrevious}
          onNext={handleNext}
          formik={formik}
          isEdit={isEdit}
          versionData={versionData}
          version={version}
          setVersion={setVersion}
        />
      )}
    </div>
  );
};

export default Party_Form;
