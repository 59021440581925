export const loginInitialValues = {
    email: "",
    password: "",
}
export const superloginInitialValues = {
    user: "",
    password: "",
}
export const roleInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
}
export const profileInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
}

export const filterRoleInitialValues = {
    status: "",
}

export const changePasswordInitialValues = {
    oldPassword: '',
    newPassword: '',
    confirmpass: '',
};

export const roleAddInitialValues = {
    roleName: "",
}
export const addDealerInitialValues = {
    zone: '',
    srNo: '',
    date: null,
    gstNuumber: '',
    panNumber: '',
    authPersonName: '',
    firmName: '',
    email: '',
    firmAddress: '',
    city: '',
    state: '',
    pincode: '',
    personName: '',
    mobile: '',
    partyReference: '',
    salesRepresentative: '',
    categoryId: '',
    creditLimit: '',
    creditDays: '',
    cdPer: '',
    samplePolicy: '',
    sequrityCheque: '',
    bankName: '',
    brandVisible: '',
    note: "",
    dealerProducts: [
        {
            size: '',
            surface: '',
            rate: '',
            remark: '',
        },
    ],

};

export const adduserInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: "",
}

export const addSizeInitialValues = {
    sizeName: "",
}

export const addSurfaceInitialValues = {
    surfaceName: "",
    surfaceDetail: "",
}

export const addCategoryInitialValues = {
    categoryName: "",
}

export const addTaxConfigInitialValues = {
    taxName: "",
    taxLabel: "",
    taxValue: "",
    description: "",
}

export const changePasswordInitialValuess = {
    newPassword: "",
    confirmpass: "",
    currentPassword: ""
}


export const sampleRequestInitialValues = {
    dealerId: "",
    dealerName: "",
    gstNumber: "",
    date: "",
    city: "",
    state: "",
    pincode: "",
    categoryId: "",
    roughPads: "",
    penPocket: "",
    loadingDate: "",
    processStartDate: "",
    materialReadyDate: "",
    desplaySize: "",
    notes: "",
    sampleProducts: [
        {
            sizeId: "",
            surfaceId: "",
            designId: "",
            sample: "",
            preview: "",
            qr: true,
            smallPCS: "",
        },
    ],
}

export const addProductInitialValues = {
    productName: "",
    surface: "",
    size: "",
    price: "",
    salePrice: "",
    tax: "",
    description: "",
    productImage: "",
    specification: ""
}