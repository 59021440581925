import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Switch,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CommonModal from "../../../components/common/Modal";
import TableSearch from "../../../components/common/Table/TableSearch";
import CommonButton from "../../../components/common/Button";
import Heading from "../../../components/Heading";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { UserDelete, UserList, UserStatusChange } from "../../../Service/user.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import Pagination from "../../../components/common/Table/Pagination";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

const User_management = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });


  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getUserList = async () => {
    setUserData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await UserList(query);
    if (res?.status) {
      setUserData(res?.data);
      setTotalData(res?.total);
    } else {
      setUserData(null);
    }
  }
  
  useEffect(() => {
    getUserList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

 
  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == 'status' ? await UserStatusChange(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await UserDelete(isModalOpen?.id) : null;

    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getUserList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message:  'Something went wrong. Please try again later.'
      }))
    }
  }

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };
  return (
   
    <>
    <Stack gap={2}>
      <Heading head={"User Management"} />
      <Stack
        mb={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <TableSearch search={search} setSearch={setSearch} setPage={setPage} />
        <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
          <CommonButton
            buttonName={"Create"}
            size="small"
            onClick={() => navigate("/add-user", { state: { isEdit: false } })}
          />
        </Stack>
      </Stack>

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell onClick={() => handleSortClick('createdAt', sortDate)}>Created Date
                  {sortDate ? <FaArrowUp style={{ color: '#adadad' }} /> : <FaArrowDown style={{ color: '#adadad' }} />}
                  </TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell sx={{ textAlign: "end" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData && userData.map((data, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 },cursor:'pointer' }}
              >
                <TableCell onClick={() => setIsModalOpen({ open: true, currentComponent: "viewuser",id:data.id })}>{data.firstName} {data.lastName}</TableCell>
                <TableCell onClick={() => setIsModalOpen({ open: true, currentComponent: "viewuser",id:data.id })}>{data.email}</TableCell>
                <TableCell onClick={() => setIsModalOpen({ open: true, currentComponent: "viewuser" ,id:data.id})}>{data.createdAt}</TableCell>
                <TableCell onClick={() => setIsModalOpen({ open: true, currentComponent: "viewuser",id:data.id })}>{data.rolename}</TableCell>
                <TableCell>
                  <Switch
                    checked={data?.status === 1 ? true : false}
                    onChange={(e) => {
                      e.stopPropagation();
                      setIsModalOpen({ id: data?.id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Edit Details">
                      <IconButton
                        onClick={() => navigate("/add-user", { state: { id: data?.id, isEdit: true } })}
                        
                        size="small"
                      >
                        <FaEdit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton size="small"
                        onClick={() => {
                          setIsModalOpen({
                            open: true,
                            currentComponent: "delete",
                            id: data.id,
                            head: "User Delete", para: "Are you sure you want to Delete the User?" 
                          });
                        }}>
                        <MdDelete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
    {userData !== null && (

        <Pagination totalRecords={totalData}
          data = {userData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />)}
      <CommonModal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "",id: "" });
          getUserList(); // Call the function after the modal is closed or an action is performed
        }}
      />
  </>
  )
}

export default User_management