import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout/Layout";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import Login from "./pages/login/Login";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./components/common/Notification/Notification";
import { loginUserApi, logoutApi } from "./state/Action/authAction";
import { loadUser } from "./Service/auth.service";
function App() {
  let routeElement = null;
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });
  const [roleName, setRoleName] = useState("admin");

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const { auth } = useSelector((state) => state?.root);
  const isAuthenticate = useSelector((store) => store?.root?.auth?.isAuthenticate);



  useEffect(() => {
    console.log("user9ds0d90d9s09ds", pathname !== "/", user, user?.role);
    if (pathname == "/login" && user && user?.role && user?.role) {
      Navigate("/");
    }
    setRoleName(user && user?.role);
  }, [user, pathname]);

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: '',
      head: '',
      id: ''
    });
    localStorage.removeItem('token');
    dispatch(logoutApi());
    Navigate('/login');
  }

  useEffect(() => {
    if (auth && auth?.isExpired) {
      setIsModalOpen({
        open: true,
        currentComponent: "logout",
        para: 'Logged out due to inactivity. Please log in again to continue.',
        head: 'Session Expired',
      });
    }
  }, [auth]);

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      dispatch(loginUserApi(res?.data));
    }
  };


  useEffect(() => {
    if (pathname !== "/login") {
      const token = localStorage.getItem("token");
      const payload = {
        token: token,
      };
      getUsetData(payload);
    }

  }, []);

  useEffect(() => {
    console.log("pathnamepathnamepathnamepathname", pathname, isAuthenticate);
    if (pathname == "/" && !isAuthenticate) {
      Navigate("/login");
    }
  }, [pathname, isAuthenticate]);




  useEffect(() => {
    if (show && show.show) {
      setShowNotification(show);
    }
  }, [show]);

  if (roleName) {
    routeElement = (
      <Layout />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="background_Image" >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={routeElement} />
        </Routes>
      </div>
      {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}

    </ThemeProvider>
  );
}

export default App;
