import { get, post,put,deleteRequest, } from "../web.request";

export const addCategory = async (data) => {
  return await post(`/addCategory`, data);
}

export const updateCategory = async (id,data) => {
  return await put(`/updateCategory/${id}`, data);
}

export const categoryList = async (query) => {
  return await get(`/categoryList${query}`);
}

export const categoryDropdown = async () => {
  return await get(`/categoryDropdown`);
}

export const deleteCategory = async (id) => {
  return await deleteRequest(`/deleteCategory/${id}`);
}

export const getCategoryById = async (id) => {
  return await get(`/categoryDetail/${id}`);
}

export const statusChangeCategory = async (id) => {
  return await put(`/statusChangeCategory/${id}`);
}


