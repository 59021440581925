import { get, post,put,deleteRequest, } from "../web.request";

export const AddSurface = async (data) => {
  return await post(`/addSurface`, data);
}

export const updateSurface = async (id,data) => {
  return await put(`/updateSurface/${id}`, data);
}

export const surfaceList = async (query) => {
  return await get(`/surfaceList${query}`);
}

export const surfaceDropdown = async () => {
  return await get(`/surfaceDropdown`);
}

export const deleteSurface = async (id) => {
  return await deleteRequest(`/deleteSurface/${id}`);
}

export const getSurfaceById = async (id) => {
  return await get(`/surfaceDetail/${id}`);
}

export const statusChangeSurface = async (id) => {
  return await put(`/statusChangeSurface/${id}`);
}


