import * as React from "react";
import Dialog from "@mui/material/Dialog";

import ChangePassword from "./ChangePassword";
import DeleteModal from "./modal/logoutModal";
import Add_Party_modal from "../../pages/Dealer-wise/Add_party_modal";
import AddSurface_modal from "../../pages/Settings/Surface/AddSurface_modal";
import AddSize_modal from "../../pages/Settings/Size/AddSize_modal";
import ViewProduct from "../../pages/Settings/Product/ViewProduct";
import AddCategory from "../../pages/Settings/Category/AddCategory";
import Add_textConfig_modal from "../../pages/Settings/Text Config/Add_textConfig_modal";
import ViewUserModel from "../../pages/Settings/User Management/View_User";
import { Update } from "@mui/icons-material";
import UpdateStatus from "./modal/UpdateStatus";
import Delete_Modal from "./modal/Delete_Modal";
import Delete from "./modal/Delete";


export default function CommonModal({
  handleClose,
  modalOpen,
  setShow,
  show,
  handelClick,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
      className={modalOpen.class}
    >
      {modalOpen.currentComponent === "changePassword" && (
        <ChangePassword
          handleClose={handleClose}
          modalOpen={modalOpen}
          setShow={setShow}
          show={show}
        />
      )}
      {modalOpen.currentComponent === "logout" && (
        <DeleteModal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "updatestatus" && (
        <UpdateStatus
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "Add Dealer" && (
        <Add_Party_modal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "AddSurface" && (
        <AddSurface_modal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "AddCategory" && (
        <AddCategory
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "Addtextconfig" && (
        <Add_textConfig_modal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "AddSize" && (
        <AddSize_modal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "viewuser" && (
        <ViewUserModel
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "deletemodal" && (
        <Delete_Modal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}

        />
      )}
      {['delete', 'status', 'changepassword'].includes(modalOpen.currentComponent) && (
        <Delete handleClose={handleClose} modalOpen={modalOpen} handelClick={handelClick} />
      )}
    </Dialog>
  );
}
