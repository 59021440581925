import { Typography } from '@mui/material'
import React from 'react'

const Heading = ({ head, smallHead }) => {
  return (
    <>
    {smallHead ? <Typography Typography fontSize={'15px'} lineHeight={'22px'} fontWeight={'600'} mb={'5px'}>{smallHead}</Typography>
      : <Typography fontSize={'20px'} lineHeight={'26px'} fontWeight={'600'}>{head}</Typography>
    }
  </>
  )
}

export default Heading