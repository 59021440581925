import React, { useState, useCallback } from "react";
import { Button, Box, IconButton, Typography, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Upload } from "@mui/icons-material";

function FileUpload1({
  labelinput,
  setShow1,
  files = [],
  setFiles,
  setDeleteImage,
  deleteImage,
}) {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event) => {
    if (
      event.target.files &&
      event.target.files[0] &&
      event.target.files[0].size > 5000000
    ) {
      setShow1({
        show: true,
        type: "error",
        message: "File is too big!" || "Something went wrong",
      });
      return;
    }

    setFiles([...files, { image: event.target.files[0] }]);
  };

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);

      const droppedFiles = Array.from(event.dataTransfer.files);
      if (droppedFiles.length) {
        const validFiles = droppedFiles.filter((file) => file.size <= 5000000);
        if (validFiles.length !== droppedFiles.length) {
          setShow1({
            show: true,
            type: "error",
            message: "One or more files are too big!" || "Something went wrong",
          });
        }
        setFiles([...files, ...validFiles.map((file) => ({ image: file }))]);
      }
    },
    [files, setFiles, setShow1]
  );

  const handleRemoveFile = (index, file) => {
    if (file?.id) {
      setDeleteImage([...deleteImage, file.id]);
    }
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <Box>
      {labelinput && (
        <Typography fontSize={"16px"} fontWeight={500} mb={"2px"}>
          {labelinput}
        </Typography>
      )}
      <label htmlFor="raised-button-file1">
        <Box
          sx={{
            width: "100%",
            height: { xs: '150px', sm: "180px", md: "200px" },
            border: "1px dashed #c4c4c4",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: dragging ? "#e0e0e0" : "#f8f8f8",
            fontSize: { xs: '15px', md: "24px" },
            fontWeight: "600",
            color: "#c4c4c4",
            cursor: "pointer",
            display: files && files.length > 0 ? "none" : "block",
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Upload sx={{ width: "40px", fontSize: "40px" }} /> Drop your files
          here or click to browse
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file1"
            type="file"
            onChange={handleFileChange}
          />
        </Box>
      </label>

      {/* Image Preview */}
      {files.length > 0 && (
        <Box
          sx={{
            mt: 2,
            position: "relative",
            width: "100%",
            height: { xs: '150px', sm: "180px", md: "200px" },
            border: "1px solid #e3e3e3",
            borderRadius: "10px",
          }}
        >
          <img
            src={files[0]?.id ? files[0]?.image : URL.createObjectURL(files[0]?.image)}
            alt="preview"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              objectFit: "contain",
            }}
          />
          <IconButton
            style={{ position: "absolute", right: 0, top: 0 }}
            onClick={() => handleRemoveFile(0, files[0])}
          >
            <DeleteIcon sx={{ fontSize: "26px" }} />
          </IconButton>
        </Box>
      )}

      {/* Thumbnails */}
      <Stack
        gap={"10px"}
        flexDirection={"row"}
        flexWrap={"wrap"}
        style={{ marginTop: "10px" }}
      >
        {files.length > 1 &&
          files.slice(1).map((file, index) => (
            <Box
              key={index + 1}
              sx={{
                width: "200px",
                height: "100px",
                position: "relative",
                border: "1px solid #e3e3e3",
                borderRadius: "10px",
              }}
            >
              <IconButton
                style={{ position: "absolute", right: 0, top: 0 }}
                onClick={() => handleRemoveFile(index + 1, file)}
              >
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>

              {file && file?.id ? (<img
                src={file?.image}
                alt={`preview ${index}`}
                style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
              />) : (
                file?.image != undefined && (<img
                  src={URL.createObjectURL(file?.image)}
                  alt={`preview ${index}`}
                  style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
                />)
              )}
            </Box>
          ))}
      </Stack>
    </Box>
  );
}

export default FileUpload1;
